export const defaultPageSize = 15;

export interface IPageable {
  sort?: string,
  sortDirection?: string,
  pageNumber: number,
  pageSize: number,
}

export const emptyPageable: IPageable = {
  pageNumber: 0,
  pageSize: defaultPageSize,
  sort: 'ASC',
};
