import * as React from 'react';
import {useCallback} from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {Redirect, useLocation} from 'react-router-dom';
// Only used for redirecting to keycloak
const Login = () => {
  const location = useLocation<{ [key: string]: unknown }>();
  const currentLocationState = location.state || {
    from: {pathname: '/'},
  };
  const {keycloak} = useKeycloak();
  const login = useCallback(() => {
    keycloak?.login();
  }, [keycloak]);

  if (keycloak?.authenticated) {
    return <Redirect to={currentLocationState?.from as string}/>;
  }
  return (<>{login()}</>
  );
};
export default Login;
