import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {ITemplateModel} from '../../models/template/ITemplateModel';
import {useStateFromProp} from '../common/StateHelper';

const MessageSection = ({
  model,
  edit,
  handleChange,
  removeFile,
}: { model: ITemplateModel, edit: boolean, handleChange: any, removeFile: any }) => {
  const {t} = useTranslation();
  const [modelState] = useStateFromProp(model);
  return (
    <>
      <div className="row">
        <div className="col">
          <label className="form-label">{t('CreateCampaign.messageLogo')}</label>
          {modelState.logoUrl ?
            <div className="row">
              <img src={modelState.logoUrl} className="image-preview" alt="logo preview"/>
              <button type="button"
                onClick={() => removeFile('logoUrl')}
                className="btn btn-link col-md-auto" hidden={!edit}>{t('Delete')}</button>
            </div> :
            <input className="form-control form-control-sm" id="formFileSm" type="file" disabled={!edit}
              name="logoUrl" onChange={handleChange} accept="image/*" required/>
          }
        </div>
        <div className="col">
          <label htmlFor="description" className="form-label">{t('CreateCampaign.messageInfo')}</label>
          <textarea className="form-control" placeholder="" aria-label="description" name="description"
            defaultValue={modelState.description} rows={4} disabled={!edit} onChange={handleChange}
            required/>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label className="form-label">{t('CreateCampaign.messageBannerImage')}</label>
          {modelState.bannerUrl ?
            <div className="row">
              <img src={modelState.bannerUrl} className="image-preview" alt="banner preview"/>
              <button type="button"
                onClick={() => removeFile('bannerUrl')}
                className="btn btn-link col-md-auto" hidden={!edit}>{t('Delete')}</button>
            </div> :
            <input className="form-control form-control-sm" id="formFileSm" type="file" disabled={!edit}
              name="bannerUrl" onChange={handleChange} accept="image/*" required/>
          }
        </div>
        <div className="col">
          <label htmlFor="buttonText"
            className="form-label">{t('CreateCampaign.messageTextBelowButton')}</label>
          <textarea className="form-control" aria-label="buttonText"
            defaultValue={modelState.buttonText} disabled={!edit} onChange={handleChange}
            name="buttonText" required/>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="qrCodeUrl" className="form-label">{t('CreateCampaign.messageBarcode')}</label>
          {modelState.qrCodeUrl ? <div className="row">
            <img src={modelState.qrCodeUrl} className="image-preview" alt="qrcode preview"/>
            <button type="button"
              onClick={() => removeFile('qrCodeUrl')}
              className="btn btn-link col-md-auto" hidden={!edit}>{t('Delete')}</button>
          </div> :
            <input className="form-control form-control-sm" id="formFileSm" type="file" disabled={!edit}
              name="qrCodeUrl" onChange={handleChange} accept="image/*" required/>
          }
        </div>
        <div className="col">
          <label htmlFor="customerContact"
            className="form-label">{t('CreateCampaign.messageContactDetails')}</label>
          <textarea className="form-control" aria-label="customerContact"
            defaultValue={modelState.customerContact} disabled={!edit} onChange={handleChange}
            name="customerContact"/>
        </div>
      </div>
    </>
  );
};
export default MessageSection;

