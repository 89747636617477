import * as React from 'react';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {activateCouponWithToken, fetchCouponWithToken} from '../utils/RestClient';
import Error, {handleError} from '../components/common/Error';
import {useTranslation} from 'react-i18next';
import Coupon from './Coupon';
import {ICampaignModel} from '../models/campaign/ICampaignModel';
import {CouponState, getCouponState, ICouponModel} from '../models/report/IReportModel';
import BlankView from './BlankView';
import Loading from '../components/common/Loading';
import {AlertManager, useAlert} from 'react-alert';

const CouponView = () => {
  const {t} = useTranslation();
  const {id} = useParams<{ id: string }>();
  const {data, error, mutate, isLoading} = fetchCouponWithToken(id);
  const [coupon, setCoupon] = useState<ICouponModel>();
  const alert: AlertManager = useAlert();

  useEffect(() => {
    if (!isLoading) {
      setCoupon({...data.coupon, state: getCouponState(data.coupon)});
    }
  }, [data]);

  if (isLoading && !data) {
    return (<Loading/>);
  }
  if (error) {
    if (error.response && error.response.data) {
      if (error.response.data.message == 'campaign.not.active') {
        return (<BlankView/>);
      }
    } else {
      return (<Error message={t('error.fetch') + ' (' + error.message + ') '}/>);
    }
    return (<BlankView/>);
  }

  const campaign: ICampaignModel = data.campaign;
  if (!coupon || !campaign) {
    return (<BlankView/>);
  }

  let remainingSeconds = 300;
  let endDate: Date = new Date();
  if (coupon.state == CouponState.ACTIVATED) {
    // console.log('Coupon is activated', coupon.activatedTimestamp);
    // If it is activated, we need to calculate remaining time in seconds
    const activatedDate = new Date(coupon?.activatedTimestamp || new Date());
    endDate = new Date(activatedDate.getTime() + 300 * 1000);
    const activated = activatedDate.getTime() / 1000;
    const now = new Date().getTime() / 1000;
    const rem = (300 - (now - activated));
    if (rem > 0) {
      remainingSeconds = Math.round(rem);
    } else {
      remainingSeconds = 0;
      setCoupon({...coupon, status: 'Used', state: CouponState.USED});
    }
  }

  const updateCouponState = (state?: CouponState) => {
    if (state == CouponState.ACTIVATED) {
      activateCouponWithToken(id).then(() => {
        mutate();
      }).catch((e: any) => {
        handleError(e, alert, t);
      });
    } else {
      setCoupon({...coupon, state: state});
      mutate();
    }
  };

  return (
    <Coupon campaign={campaign} coupon={coupon} changeCouponState={updateCouponState}
      counterSeconds={remainingSeconds} endTime={endDate}/>
  );
};

export default CouponView;
