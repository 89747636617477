import {useKeycloak} from '@react-keycloak/web';
import {IRoleModel} from '../models/usermanagement/IRole';

export enum Role {
  ROLE_ADMIN = 'Admin',
  ROLE_OPERATOR = 'Operator',
  ROLE_USER = 'User',
  ROLE_GUEST = 'Guest',
}

export enum Resource {
  CAMPAIGNS,
  REPORTS,
  TEMPLATES,
  USERS,
  GROUPS,
}

export enum AccessType {
  READ,
  WRITE,
}

const isUser = (roles?: IRoleModel[]): boolean => {
  if (roles) {
    return roles.length == 1 && roles[0].name.toLowerCase() === Role.ROLE_USER.toLowerCase();
  }
  // Check current user
  const {keycloak} = useKeycloak();
  const access = keycloak.realmAccess;
  return !!access &&
    (access.roles.findIndex((r) => r.toLocaleLowerCase() === Role.ROLE_USER.toLocaleLowerCase()) >= 0 &&
    access.roles.findIndex((r) => r.toLocaleLowerCase() === Role.ROLE_ADMIN.toLocaleLowerCase()) < 0 &&
    access.roles.findIndex((r) => r.toLocaleLowerCase() === Role.ROLE_OPERATOR.toLocaleLowerCase()) < 0 &&
    access.roles.findIndex((r) => r.toLocaleLowerCase() === Role.ROLE_GUEST.toLocaleLowerCase()) < 0);
};

const hasRole = (roles: string[]) => {
  const {keycloak} = useKeycloak();
  const access = keycloak.realmAccess;
  return roles.some((role: string) => {
    return !!access && access.roles.findIndex((r) => r.toLocaleLowerCase() === role.toLocaleLowerCase()) >= 0;
  });
};

// Checks whether user has write access to given resource
const userCanCreate = (resource: Resource): boolean => {
  for (const role of Object.values(Role)) {
    if (hasRole([role])) {
      return userHasWriteAccess(resource, role as Role);
    }
  }
  return false;
};
// Checks whether user has read access to given resource
const userCanRead = (resource: Resource): boolean => {
  for (const role of Object.values(Role)) {
    if (hasRole([role])) {
      return userHasReadAccess(resource, role as Role);
    }
  }
  return false;
};

const allowedRoles = (): string[] => {
  const {keycloak} = useKeycloak();
  const access = keycloak.realmAccess;

  if (!access || access.roles.length == 0) {
    return [];
  }

  if (access.roles.findIndex((r) => Role.ROLE_ADMIN.toLocaleLowerCase() === r.toLocaleLowerCase()) >= 0) {
    // Return all
    return [Role.ROLE_ADMIN, Role.ROLE_OPERATOR, Role.ROLE_USER, Role.ROLE_GUEST];
  }
  if (access.roles.findIndex((r) => Role.ROLE_OPERATOR.toLocaleLowerCase() === r.toLocaleLowerCase()) >= 0) {
    // Operator. REturn only users
    return [Role.ROLE_USER, Role.ROLE_GUEST];
  }
  // Chould not happen
  return [];
};

const AuthUtil = {
  allowedRoles,
  isUser,
  hasRole,
  userCanCreate,
  userCanRead,
};
export default AuthUtil;

function userHasWriteAccess(resource: Resource, role: Role): boolean {
  switch (role) {
    case Role.ROLE_ADMIN:
      return true; // Admin can access everything
    case Role.ROLE_OPERATOR:
      return operatorAccess(resource, AccessType.WRITE);
    case Role.ROLE_USER:
      return userAccess(resource, AccessType.WRITE);
    case Role.ROLE_GUEST:
      return guestAccess(resource, AccessType.WRITE);
  }
  return false;
}

function userHasReadAccess(resource: Resource, role: Role): boolean {
  switch (role) {
    case Role.ROLE_ADMIN:
      return true; // Admin can access everything
    case Role.ROLE_OPERATOR:
      return operatorAccess(resource, AccessType.READ);
    case Role.ROLE_USER:
      return userAccess(resource, AccessType.READ);
    case Role.ROLE_GUEST:
      return guestAccess(resource, AccessType.READ);
  }
  return false;
}

function guestAccess(resource: Resource, accessType: AccessType): boolean {
  if (accessType == AccessType.READ) {
    switch (resource) {
      case Resource.REPORTS:
        return true;
    }
    return false;
  } else {
    return false;
  }
}

function operatorAccess(resource: Resource, accessType: AccessType): boolean {
  if (accessType == AccessType.READ) {
    switch (resource) {
      case Resource.CAMPAIGNS:
      case Resource.REPORTS:
      case Resource.TEMPLATES:
      case Resource.USERS:
      case Resource.GROUPS:
        return true;
    }
  } else {
    switch (resource) {
      case Resource.CAMPAIGNS:
      case Resource.USERS:
      case Resource.TEMPLATES:
        return true;
    }
    return false;
  }
}

function userAccess(resource: Resource, accessType: AccessType): boolean {
  if (accessType == AccessType.READ) {
    switch (resource) {
      case Resource.CAMPAIGNS:
      case Resource.REPORTS:
      case Resource.TEMPLATES:
        return true;
    }
    return false;
  } else {
    switch (resource) {
      case Resource.CAMPAIGNS:
        return true;
    }
    return false;
  }
}
