import * as React from 'react';
import {fetchCampaigns} from '../../utils/RestClient';
import CampaignTable from './CampaignTable';
import {useTranslation} from 'react-i18next';
import Loading from '../common/Loading';
import Error from '../common/Error';
import {useHistory} from 'react-router-dom';
import {Views} from '../common/router/AppRouter';
import {CampaignState} from '../../models/campaign/ICampaignModel';

const MainView = () => {
  const {data, error, isLoading} = fetchCampaigns({
    pageNumber: 0,
    pageSize: 5,
    sort: 'startDate',
    sortDirection: 'ASC',
  }, '', CampaignState.STARTING);
  const {data: active, error: errorActive, isLoading: isLoadingActive} = fetchCampaigns({
    pageNumber: 0,
    pageSize: 5,
    sort: 'startDate',
    sortDirection: 'ASC',
  }, '', CampaignState.ACTIVE);
  const {data: finished, error: errorFinished, isLoading: isLoadingFinished} = fetchCampaigns({
    pageNumber: 0,
    pageSize: 5,
    sort: 'endDate',
    sortDirection: 'DESC',
  }, '', CampaignState.FINISHED);
  const {t} = useTranslation();
  const history = useHistory<any>();

  if (isLoading || isLoadingActive || isLoadingFinished) {
    return (<Loading/>);
  }
  if (error || errorActive || errorFinished) {
    const e = error || errorActive || errorFinished;
    return (<Error message={t('error.fetch') + ' (' + e.message + ') '}/>);
  }

  function buttonClick(state: CampaignState) {
    history.push(Views.listCampaigns, {filter: state});
  }

  return (
    <div className="container-fluid p-5">
      <div className="container-sm p-5">
        <h2 className="text-center mb-5">{t('MainCampaigns.latest')}</h2>
        <div className="row">
          <div className="col">
            <CampaignTable models={data.content || []} state={CampaignState.STARTING}/>
          </div>
        </div>
        <div className="row ">
          <div className="col text-center py-3">
            <button type="button" className="btn btn-primary"
              onClick={() => buttonClick(CampaignState.STARTING)}>{t('MainCampaigns.btn.latest')}</button>
          </div>
        </div>
      </div>
      <div className="container-sm p-5">
        <h2 className="text-center mb-5">{t('MainCampaigns.active')}</h2>
        <div className="row">
          <div className="col">
            <CampaignTable models={active.content || []} state={CampaignState.ACTIVE}/>
          </div>
        </div>
        <div className="row ">
          <div className="col text-center py-3">
            <button type="button" className="btn btn-primary"
              onClick={() => buttonClick(CampaignState.ACTIVE)}>{t('MainCampaigns.btn.active')}</button>
          </div>
        </div>
      </div>
      <div className="container-sm p-5">
        <h2 className="text-center mb-5">{t('MainCampaigns.finished')}</h2>
        <div className="row">
          <div className="col">
            <CampaignTable models={finished.content || []} state={CampaignState.FINISHED} />
          </div>
        </div>
        <div className="row ">
          <div className="col text-center py-3">
            <button type="button" className="btn btn-primary"
              onClick={() => buttonClick(CampaignState.FINISHED)}>{t('MainCampaigns.btn.finished')}</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainView;
