import * as React from 'react';
import {useState} from 'react';
import CampaignTable from './CampaignTable';
import {useTranslation} from 'react-i18next';
import Paging from '../common/Paging';
import {fetchCampaigns} from '../../utils/RestClient';
import Loading from '../common/Loading';
import Error from '../common/Error';
import {emptyPageable, IPageable} from '../../models/common/IPageable';
import {useHistory} from 'react-router-dom';
import {CampaignState} from '../../models/campaign/ICampaignModel';
import CampaignStateButton from '../common/CampaignStateButton';
import {IGroupModel} from '../../models/usermanagement/IGroupModel';
import GroupSelectFilterComponent from '../usermanagement/GroupSelectFilterComponent';

const CampaignsView = () => {
  const {t} = useTranslation();
  const history = useHistory<any>();
  let filterParam = CampaignState.ANY;
  if (history?.location.state?.filter) {
    filterParam = history.location.state.filter;
  }
  const [page, setPage] = useState<IPageable>({...emptyPageable, sort: 'createdDate', sortDirection: 'DESC'});
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<CampaignState>(filterParam);
  const [groupFilter, setGroupFilter] = useState<IGroupModel[]>([]);
  const {data, error, isLoading} = fetchCampaigns(page, search, filter, groupFilter);

  if (isLoading && !data.content) {
    return (<Loading/>);
  }
  if (error) {
    return (<Error message={t('error.fetch') + ' (' + error.message + ') '}/>);
  }

  function onFilter(state: CampaignState) {
    setFilter(state);
    changePage(0);
  }

  function onGroupFilter(group: IGroupModel, add: boolean = true) {
    const groups = groupFilter;
    const index = groups.indexOf(group);
    if (add) {
      if (index < 0) {
        groups.push(group);
      }
    } else {
      // Remove
      if (index > -1) {
        groups.splice(index, 1);
      }
    }
    setGroupFilter(groups);
    changePage(0);
  }

  function changePage(pageNbr: number) {
    setPage({...page, pageNumber: pageNbr});
  }

  function changeSort(sort: IPageable) {
    setPage({...page, sort: sort.sort, sortDirection: sort.sortDirection});
  }

  function onSearch(event: any) {
    const searchTerm = event.target.value;
    if (searchTerm && searchTerm.length > 2) {
      setSearch(searchTerm);
      changePage(0);
    } else if (searchTerm.length == 0) {
      setSearch('');
      changePage(0);
    }
  }

  return (<div className="container-fluid">
    <div className="container-sm p-2">
      <h1 id="top-header" className="text-center">{t('Campaigns.header')}</h1>
      <div id="filter-buttons" className="row w-100">
        <div className="col-auto py-5">
          <CampaignStateButton filter={CampaignState.ANY} filterState={filter} onFilter={onFilter}
            label={t('Campaigns.filter.all')}/>
        </div>
        <div className="col-auto py-5">
          <CampaignStateButton filter={CampaignState.ACTIVE} filterState={filter} onFilter={onFilter}
            label={t('Campaigns.filter.active')}/>
        </div>
        <div className="col-auto py-5">
          <CampaignStateButton filter={CampaignState.STARTING} filterState={filter} onFilter={onFilter}
            label={t('Campaigns.filter.starting')}/>
        </div>
        <div className="col-auto py-5">
          <CampaignStateButton filter={CampaignState.FINISHED} filterState={filter} onFilter={onFilter}
            label={t('Campaigns.filter.ended')}/>
        </div>
        <div className="col-auto py-5">
          <CampaignStateButton filter={CampaignState.ATTACHED} filterState={filter} onFilter={onFilter}
            label={t('Campaigns.filter.attached')}/>
        </div>
        <div className="col-auto py-5">
          <CampaignStateButton filter={CampaignState.ARCHIVED} filterState={filter} onFilter={onFilter}
            label={t('Campaigns.filter.archived')}/>
        </div>
        <div className="col py-4">
          <div className="row">
            <label htmlFor="userSearch"
              className="col text-end col-form-label">{t('CampaignTable.col.client')}</label>
            <div className="col">
              <GroupSelectFilterComponent addGroup={onGroupFilter} removeGroup={(g: IGroupModel) => {
                onGroupFilter(g, false);
              }} groups={groupFilter}/>
            </div>
          </div>
          <div className="row">
            <label htmlFor="reportSearch" className="col text-end col-form-label">{t('SearchPhrase')}</label>
            <div className="col">
              <input type="text" id="campaignSearch" name="campaignSearch" className="form-control"
                onChange={onSearch} placeholder={t('Campaigns.filter.search')}/>
            </div>
          </div>
        </div>
      </div>
      <CampaignTable models={data.content || []} showStateCol={true} sort={page} changeSort={changeSort} state={filter}/>
    </div>
    <Paging changePage={changePage} itemCount={data.totalElements || 0} currentPage={page.pageNumber}
      pageSize={page.pageSize}/>
  </div>
  );
};

export default CampaignsView;
