import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {IUserModel} from '../../models/usermanagement/IUserModel';
import {useStateFromProp} from '../common/StateHelper';
import {groupArrayToString} from '../../models/usermanagement/IGroupModel';
import AuthUtil from '../../utils/AuthUtil';

const ListUsers = ({models, editUser}: { models: any, editUser: any }) => {
  const {t} = useTranslation();
  const [users] = useStateFromProp(models);
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">{t('UserTable.col.ID')}</th>
          <th scope="col">{t('UserTable.col.firstName')}</th>
          <th scope="col">{t('UserTable.col.lastName')}</th>
          <th scope="col">{t('UserTable.col.group')}</th>
          <th scope="col">{t('UserTable.col.amountOfCampaigns')}</th>
          <th scope="col">{t('UserTable.col.amountOfCodes')}</th>
          <th scope="col">{t('UserTable.col.role')}</th>
          <th scope="col">{t('UserTable.col.campaignsReadonly')}</th>
        </tr>
      </thead>
      <tbody>
        {
          users.map((item: IUserModel, idx: number) => (
            <tr key={idx}>
              <td>{item.id}</td>
              <td>{item.firstName}</td>
              <td>{item.lastName}</td>
              <td>{groupArrayToString(item.groups)}</td>
              <td>{item.campaignCount || 0}</td>
              <td>{item.couponCount || 0}</td>
              <td>{item.roles.map((role) => (t('Role.' + role.name) + ' '))}</td>
              {AuthUtil.isUser(item.roles) ?
                <td>{item.campaignsReadonly ? t('No'): t('Yes')}</td> :
                <td></td>
              }
              <td>
                <button type="button" className="btn btn-primary"
                  onClick={() => editUser(item)}>{t('Manage')}
                </button>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>);
};
export default ListUsers;
