import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {AlertManager} from 'react-alert';

const Error = ({message = ''}: any) => {
  const {t} = useTranslation();
  return (
    <div className="container-fluid m-5">
      <div className="alert alert-danger" role="alert">
        {t('error.error')} {message}
      </div>
    </div>
  );
};
export default Error;

export const handleError = (error: any, alert?: AlertManager, t?: any) => {
  console.log('Virhe haettaessa tietoja ', error);
  if (error.response) {
    alert?.error(t('error.' + error.response.data.message) || error.response.data.message);
  }
};

export const handleSendError = (error: any, alertManager?: AlertManager, t?: any) => {
  console.log('Virhe lähettäessä viestejä ', error);
  if (error.response) {
    // eslint-disable-next-line no-unused-vars
    let errorsMsg: string = '';
    if (error.response.data && Array.isArray(error.response.data.errors) && error.response.data.errors.length > 0) {
      errorsMsg = '\n';
      const errors = error.response.data.errors;
      for (const e of errors) {
        errorsMsg += '\n';
        errorsMsg += t('error.' + e.value);
        if (e.field) {
          // eslint-disable-next-line no-unused-vars
          errorsMsg += ' (' + e.field + ')';
        }
      }
    }
    // eslint-disable-next-line no-unused-vars
    const err: string = t('error.' + error.response.data.message);
    alert(err + errorsMsg);
    // alert?.error(err + errorsMsg);
  }
};
