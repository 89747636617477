import {IGroupModel} from './IGroupModel';
import {IBaseModel} from '../common/IBaseModel';
import {IRoleModel} from './IRole';

export interface IUserModel extends IBaseModel {
  email: string,
  firstName: string,
  lastName: string,
  customerRef: string,
  billingCompany: string,
  phoneNumber: string,
  groups?: IGroupModel[],
  roles: IRoleModel[],
  password?: string,
  couponCount?: number,
  campaignCount?: number,
  campaignsReadonly: boolean
}

export const userModelEmpty: IUserModel = {
  id: 0,
  firstName: '',
  lastName: '',
  roles: [],
  groups: [],
  email: '',
  customerRef: '',
  billingCompany: '',
  phoneNumber: '',
  campaignsReadonly: false,
};
