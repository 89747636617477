import * as React from 'react';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {groupModelEmpty, IGroupModel} from '../../models/usermanagement/IGroupModel';
import {IUserModel} from '../../models/usermanagement/IUserModel';
import {useStateFromProp} from '../common/StateHelper';
import UserSelectFilterComponent from './UserSelectFilterComponent';
import {Modal} from 'bootstrap';

const CreateGroup = ({
  group,
  addGroup,
  modal,
  setModal,
}: {
  group: IGroupModel,
  addGroup: any,
  modal: any,
  setModal: any
}) => {
  const {t} = useTranslation();
  const groupModal: any = useRef();
  const [groupState, setGroupState] = useStateFromProp(group);
  const isCreate = groupState.id.length == 0;

  useEffect(() => {
    setModal(new Modal(groupModal.current),
    );
  }, []);

  function handleSubmit(event: any) {
    event.preventDefault();
    addGroup(groupState);
    setGroupState({...groupModelEmpty, users: []});
    modal.hide();
  }

  function handleChange(event: any) {
    const {name, value} = event.currentTarget;
    setGroupState({...groupState, [name]: value});
  }

  function addUser(user: IUserModel) {
    const groupUsers: IUserModel[] = groupState.users || [];
    // Add user if its found and not already assigned to this group
    if (user && !groupUsers?.includes(user)) {
      groupUsers.push(user);
      setGroupState({...groupState, users: groupUsers});
    }
  }

  /* Remove user was implemented, but not needed
            function removeUser(selectedUser: IListUserModel) {
              const users: IListUserModel[] = groupState.users || [];
              const index = users.indexOf(selectedUser);
              if (index > -1) {
                users.splice(index, 1);
                setGroupState({...groupState, users: users});
              }
            }*/


  return (
    <div className="modal fade" ref={groupModal} aria-labelledby="createGroupModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title"
              id="createGroupModalLabel">{isCreate ? t('CreateGroup.CreateGroup') : t('CreateGroup.EditGroup')}</h2>
            <button type="button" className="btn-close" onClick={() => modal!.hide()}
              aria-label="Close"/>
          </div>
          <div className="modal-body">
            <div className="container-sm">
              <form onSubmit={handleSubmit} className="groupForm">
                <div className="row">
                  <div className="col">
                    <label htmlFor="name"
                      className="form-label">{t('GroupTable.col.name')}</label>
                    <input id="name" type="text" className="form-control" name="name"
                      value={groupState.name} onChange={handleChange}/>
                  </div>
                  <div className="col" hidden={true}> {/* Users cannot be added through groups atm */}
                    <label htmlFor="userAdd"
                      className="form-label">{t('CreateGroup.AddUsers')}</label>
                    <UserSelectFilterComponent addUser={addUser} removeUser={() => console.log('remove user')}
                      users={groupState.users}/>
                  </div>

                </div>
                <div className="p-5">
                  <h3>{t('CreateGroup.groupUsers')}</h3>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">{t('UserTable.col.ID')}</th>
                        <th scope="col">{t('UserTable.col.firstName')}</th>
                        <th scope="col">{t('UserTable.col.lastName')}</th>
                        <th scope="col">{t('UserTable.col.role')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        group.users?.map((item: IUserModel) => (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.firstName}</td>
                            <td>{item.lastName}</td>
                            <td>{item.roles?.map((role) => (t('Role.' + role.name) + ' '))}</td>
                            <td>
                              { /* <button type="button" className="btn-sm btn-danger"
                                onClick={() => removeUser(item)}>{t('CreateGroup.removeUser')}
                              </button>
                              */
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                <div className="modal-footer">
                  { /* Delete done in the "upper" level
                    <button className="btn btn-danger" disabled={isCreate}
                            onClick={deleteGroup}>{t('CreateGroup.Remove')}
                    </button>
                    */
                  }
                  <button type="button" className="btn btn-secondary"
                    onClick={() => modal.hide()}>{t('Cancel')}
                  </button>
                  <button type="submit"
                    className="btn btn-primary">{t('CreateGroup.Save')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default CreateGroup;

