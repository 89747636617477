// Custom date functions for converting date to desired format
import dayjs from 'dayjs';

const dateformat: string = 'DD.MM.YYYY HH:mm';
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export interface DefaultDate {
  date: Date | null | undefined
}

function getDateFromString(date?: string, defaultValue?: DefaultDate): Date {
  if (date) {
    return dayjs(date, dateformat).toDate();
  } else {
    // TODO what is the best default for our usage? undefined or new date?
    return defaultValue ? defaultValue.date as Date : new Date();
  }
}

function getStringFromDate(date?: Date | null): string {
  if (!date) {
    return '';
  }
  const customParseFormat = require('dayjs/plugin/customParseFormat');
  dayjs.extend(customParseFormat);
  return dayjs(date).format(dateformat);
}

export function getCampaignDefaultStartDate(): string {
  const date = new Date();
  date.setHours(date.getHours() + 1);
  return getStringFromDate(date);
}

export function getCampaignDefaultEndDate(): string {
  const date = new Date();
  date.setHours(date.getHours() + 1);
  date.setMonth(date.getMonth() + 1);
  return getStringFromDate(date);
}

function getShortStringFromDate(date?: Date | null): string {
  if (!date) {
    return '';
  }
  if (date instanceof String) {
    date = new Date(date);
  }
  const customParseFormat = require('dayjs/plugin/customParseFormat');
  dayjs.extend(customParseFormat);
  return dayjs(date).format('DD.MM.YYYY');
}


const DateUtil = {
  getDateFromString,
  getStringFromDate,
  getShortStringFromDate,
};
export default DateUtil;
