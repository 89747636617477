import {ICampaignModel} from '../models/campaign/ICampaignModel';
import * as React from 'react';
import {useRef} from 'react';
import {Modal} from 'bootstrap';
import Coupon from './Coupon';
import {CouponState, ICouponModel} from '../models/report/IReportModel';

function emptyCoupon(): ICouponModel {
  return {
    id: 0,
    status: 'Delivered',
    code: '',
    messageId: '',
    phoneNumber: '',
    deliveredTimestamp: new Date(),
  };
}

const CouponPreviewModal = ({
  campaignParameter,
  btnLabel,
}: { btnLabel: string, campaignParameter: ICampaignModel }) => {
  const previewModal: any = useRef();
  const [coupon, setCoupon] = React.useState<ICouponModel>(emptyCoupon());
  const showPreview = () => {
    const modalElement = previewModal.current;
    const bootstrapModal = new Modal(modalElement, {
      backdrop: 'static',
      keyboard: false,
    });
    bootstrapModal.show();
  };
  const hideModal = (e: any) => {
    e.preventDefault();
    setCoupon({...emptyCoupon(), status: 'New', state: CouponState.NEW});
    const modalElement = previewModal.current;
    const bootstrapModal = Modal.getInstance(modalElement);
    bootstrapModal?.hide();
  };
  return (<>
    <button type="button" key="preview" className="btn btn-primary" disabled={false}
      onClick={showPreview}>{btnLabel}</button>
    <div id="couponPreviewModal" className="modal fade" ref={previewModal} aria-labelledby="previewCampaign"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title text-center"
              id="createGroupModalLabel"><span>{campaignParameter.name}</span></h2>
            <button type="button" key="preview" className="btn-close" onClick={hideModal}
              aria-label="Close"/>
          </div>
          <div className="modal-body">
            <Coupon campaign={campaignParameter}
              coupon={coupon}
              changeCouponState={(state: CouponState) => {
                setCoupon({...coupon, state: state});
              }} counterSeconds={300}/>
          </div>
        </div>
      </div>
    </div>
  </>);
};
export default CouponPreviewModal;
