import React, {useEffect, useState} from 'react';
import {ICampaignModel} from '../../models/campaign/ICampaignModel';
import {useHistory} from 'react-router-dom';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useTranslation} from 'react-i18next';
import fi from 'date-fns/locale/fi';
import {createCampaign, updateCampaign, fetchCurrentUser} from '../../utils/RestClient';
import {handleError} from '../common/Error';
import {useKeycloak} from '@react-keycloak/web';
import BasicInfoSection from './BasicInfoSection';
import MessageSection from './MessageSection';
import {Views} from '../common/router/AppRouter';
import TemplateSelectComponent from '../template/TemplateSelectComponent';
import AuthUtil, {Role} from '../../utils/AuthUtil';
import DateUtil from '../../utils/DateUtils';
import UserSelectFilterComponent from '../usermanagement/UserSelectFilterComponent';
import {IUserModel} from '../../models/usermanagement/IUserModel';
import CouponPreviewModal from '../../mobile/CouponPreviewModal';
import {AlertManager, useAlert} from 'react-alert';
import GroupSelectFilterComponent from '../usermanagement/GroupSelectFilterComponent';
import {IGroupModel} from '../../models/usermanagement/IGroupModel';

const FormData = require('form-data');

const CreateCampaignView = ({
  campaign,
  edit,
  viewMode = false,
}: { campaign: ICampaignModel, edit: boolean, viewMode?: boolean }) => {
  const history = useHistory();
  const [campaignState, setCampaignState] = useState(campaign);
  const alert: AlertManager = useAlert();
  // Put uploaded files into state until we post the data
  const [logo, setLogo] = useState('');
  const [banner, setBanner] = useState('');
  const [qrCode, setQrCode] = useState('');
  const {t, i18n} = useTranslation();
  const {keycloak} = useKeycloak();
  const [editMode, setEditMode] = useState(edit);
  const [attached, setAttached] = useState(!campaign.endDate);
  const [groupsFetched, setGroupsFetched] = useState([]);
  const [campaignsReadonly, setCampaignsReadonly] = useState(false);

  useEffect(() => {
    fetchCurrentUser(keycloak).then((res) => {
      if (res.data) {
        setCampaignsReadonly((res.data as IUserModel).campaignsReadonly);
      } else {
        keycloak.loadUserInfo().then((value) => {
          setCampaignsReadonly(value && (value as any).campaignsReadonly && (value as any).campaignsReadonly === true ? true : false);
        });
      }
    }).catch(handleError);
  });

  registerLocale('fi', fi);

  function handleSubmit(event: any) {
    event?.preventDefault();
    const formData = new FormData();
    const campaign = campaignState;

    if (logo) {
      formData.append('logo', logo);
    }
    if (banner) {
      formData.append('banner', banner);
    }
    if (qrCode) {
      formData.append('qrcode', qrCode);
    }
    if (!campaign.groups || campaign.groups.length == 0) {
      // Set group if nothing is set.. this should only happen for User role, since the selection is not visible
      if (groupsFetched.length > 0) {
        campaign.groups = [groupsFetched[0]];
      }
    }

    formData.append('campaign', new Blob([JSON.stringify(campaign)], {type: 'application/json'}));
    if (campaignState.id > 0) {
      console.log('Update campaign', campaign);
      updateCampaign(campaign, keycloak, formData).then((res: any) => {
        if (!viewMode) {
          history.push(Views.listCampaigns);
        } else {
          if (res.data) {
            setCampaignState(res.data);
          }
          toggleEdit();
        }
      }).catch((e) => handleError(e, alert, t));
    } else {
      console.log('Create campaign', campaign);
      createCampaign(campaign, keycloak, formData).then(() => {
        history.push(Views.listCampaigns);
      }).catch((e) => handleError(e, alert, t));
    }
  }

  function toggleEdit(event?: any) {
    event?.preventDefault();
    setEditMode(!editMode);
  }

  function removeAttachment(name: string) {
    if (name == 'bannerUrl') {
      setBanner('');
    } else if (name == 'logoUrl') {
      setLogo('');
    } else if (name == 'qrCodeUrl') {
      setQrCode('');
    }
    setCampaignState({...campaignState, [name]: ''});
  }

  function handleBackClick() {
    history.goBack();
  }

  function useTemplate(template: any) {
    delete template.id; // Need to remove template id when applying it
    delete template.version; // Also remove version
    delete template.groups;
    setCampaignState({...campaignState, ...template});
  }

  function handleChange(event: any) {
    const {name, value} = event.currentTarget;
    if (event.target.files) {
      const file = event.target.files[0];
      if (name == 'bannerUrl') {
        setBanner(file);
      } else if (name == 'logoUrl') {
        setLogo(file);
      } else if (name == 'qrCodeUrl') {
        setQrCode(file);
      }
    } else {
      setCampaignState({...campaignState, [name]: value});
    }
  }

  return (
    <div className="fullscreen-form">
      <form onSubmit={handleSubmit} className="campaignForm" id="campaignForm">
        <div className="row" hidden={!editMode}>
          <div className="col"><h2>{t('CreateCampaign.create')}</h2></div>
        </div>
        <div className="row">
          <div className="col"><h3>{t('CreateCampaign.nameAndValue')}</h3></div>
        </div>
        <BasicInfoSection campaign={campaignState} edit={editMode} handleChange={handleChange} />
        <div className="row">
          <div className="col"><h3>{t('CreateCampaign.messagePage')}</h3></div>
        </div>
        <div className="row m-0">
          <div className="col m-0 p-0">
            <div className="row">
              <div className="col" hidden={!editMode}>
                <label htmlFor="name" className="form-label">{t('CreateCampaign.selectTemplate')}</label>
                <TemplateSelectComponent selectTemplate={useTemplate}/>
              </div>
              <div className="col">
                <label htmlFor="title" className="form-label">{t('CreateCampaign.messageHeader')}</label>
                <textarea className="form-control" placeholder="" aria-label="message header"
                  defaultValue={campaignState.title} disabled={!editMode} onChange={handleChange}
                  name="title"/>
              </div>
            </div>
            <MessageSection model={campaignState} edit={editMode} handleChange={handleChange}
              removeFile={removeAttachment}/>
          </div>
          <div className="col-auto my-4">
            <img src="/img/kuponki_layout.png" alt="kuponki" className="layout-img"/>
          </div>
        </div>
        <div className="row">
          <div className="col"><h3>Kampanja-aika</h3></div>
        </div>
        <div className="row">
          <div className="col">
            <label htmlFor="name" className="form-label">Aloitus:</label>
            <DatePicker selected={DateUtil.getDateFromString(campaignState.startDate)}
              name="startDate"
              onChange={(date: Date) => {
                setCampaignState({...campaignState, startDate: DateUtil.getStringFromDate(date)});
              }}
              dateFormat="dd.MM.yyyy HH:mm"
              locale={i18n.language}
              timeCaption={t('Time')}
              showTimeSelect disabled={!editMode} className="calendar"/>
          </div>
          <div className="col">
            <label htmlFor="name" className="form-label">Lopetus:</label>
            <div hidden={attached}>
              <DatePicker selected={DateUtil.getDateFromString(campaignState.endDate)}
                name="endDate"
                onChange={(date: Date) => {
                  setCampaignState({...campaignState, endDate: DateUtil.getStringFromDate(date)});
                }}
                dateFormat="dd.MM.yyyy HH:mm" locale={i18n.language}
                timeCaption={t('Time')}
                showTimeSelect disabled={!editMode || attached} className="calendar"/>
            </div>
          </div>
          <div className="col">
            <label htmlFor="name" className="form-label">Kiinnitetty kampanja</label>
            <div className="form-check form-switch">
              <label htmlFor="name" className="form-check-label">Kyllä</label>
              <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                disabled={!editMode} defaultChecked={attached} onChange={(e) => {
                  setAttached(e.target.checked);
                  if (e.target.checked) {
                    setCampaignState({...campaignState, endDate: undefined});
                  }
                }}/>
            </div>
            Kampanja julkaistaan ilman päättymispäivää ja on aktiivinen kunnes poistetaan
          </div>
        </div>
        <div className="row" hidden={!AuthUtil.hasRole([Role.ROLE_ADMIN, Role.ROLE_OPERATOR])}>
          <div className="col"><h3>Käyttöoikeudet</h3></div>
        </div>
        <div className="row" hidden={!AuthUtil.hasRole([Role.ROLE_ADMIN, Role.ROLE_OPERATOR])}>
          <div className="col">
            {t('CreateCampaign.addGroup')}
            <GroupSelectFilterComponent addGroup={(group: IGroupModel) => {
              const groups = campaignState.groups || [];
              if (!groups.some((g: IGroupModel) => g.id == group.id)) {
                groups.push(group);
                setCampaignState({...campaignState, groups: groups});
              }
            }} removeGroup={(group: IGroupModel) => {
              const groups = campaignState.groups || [];
              const index = groups.indexOf(group);
              if (index > -1) {
                groups.splice(index, 1);
                setCampaignState({...campaignState, groups: groups});
              }
            }} groups={campaignState.groups} enabled={editMode} groupsFetched={setGroupsFetched}/>
          </div>
          <div className="col"/>
          <div className="col">
            {t('CreateCampaign.addUser')}
            <UserSelectFilterComponent addUser={(user: IUserModel) => {
              const users = campaignState.users || [];
              if (!users.some((u) => u.id == user.id)) {
                users.push(user);
                setCampaignState({...campaignState, users: users, userIds: users.map((u) => u.id)});
              }
            }} enabled={editMode} users={campaignState.users} removeUser={
              (user: IUserModel) => {
                const users = campaignState.users || [];
                const index = users.indexOf(user);
                if (index > -1) {
                  users.splice(index, 1);
                  setCampaignState({...campaignState, users: users, userIds: users.map((u) => u.id)});
                }
              }
            }/>
          </div>
        </div>
        <div className="row py-5" hidden={viewMode}>
          <div className="col text-center">
            <button className="btn btn-primary" onClick={handleBackClick}>{t('Exit')}</button>
          </div>
          <div className="col text-center">
            <CouponPreviewModal campaignParameter={campaignState} btnLabel={t('Preview')}/>
          </div>
          <div className="col text-center" hidden={viewMode}>
            <button type="submit" className="btn btn-primary">{t('Publish')}</button>
          </div>
        </div>
        {!campaignsReadonly &&
          <div className="row py-5 " hidden={!viewMode}>
            <div className="col ">
              <div className="row p-1" hidden={editMode}>
                <div className="col text-center">
                  <button className="btn btn-primary btn-lg"
                    onClick={toggleEdit}>{t('CreateCampaign.openForEdit')}</button>
                </div>
              </div>
              <div className="row p-1" hidden={!editMode}>
                <div className="col text-center">
                  <button className="btn btn-warning btn-lg">{t('CreateCampaign.save')}</button>
                </div>
              </div>
              <div className="row p-1" hidden={true}>
                <div className="col text-center">
                  <button className="btn btn-success btn-lg" onClick={() => {
                  }}>Muutokset tallennettu
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
      </form>
    </div>
  );
};
export default CreateCampaignView;
