import * as React from 'react';
import {useEffect, useState} from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {fetchCurrentUser} from '../../utils/RestClient';
import {handleError} from './Error';
import AuthUtil, {Resource} from '../../utils/AuthUtil';
import {IUserModel} from '../../models/usermanagement/IUserModel';

const Header = () => {
  const {keycloak} = useKeycloak();
  const [name, setName] = useState('');
  const [campaignsReadonly, setCampaignsReadonly] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    if (keycloak?.authenticated) {
      fetchCurrentUser(keycloak).then((res) => {
        if (res.data) {
          if (res.data.firstName && res.data.lastName) {
            setName(res.data.firstName + ' ' + res.data.lastName);
          }
          setCampaignsReadonly((res.data as IUserModel).campaignsReadonly);
        } else {
          keycloak.loadUserInfo().then((value) => {
            setName((value as any).email);
            setCampaignsReadonly(value && (value as any).campaignsReadonly && (value as any).campaignsReadonly === true ? true : false);
          });
        }
      }).catch(handleError);
    }
  }, []);

  if (!keycloak?.authenticated) {
    return (<></>);
  }

  function logout() {
    keycloak.logout();
  }

  return (
    <>
      <div className="container-fluid header py-4">
        <div className="row  px-4">
          <div className="col-auto me-auto logo">
            <Link to="/"><img src="/img/digiviesti-fi.png" className="img-fluid" alt="logo"/></Link>
          </div>
          <div className="col-auto">
            <Link to="/">{t('Header.hello')} {name}</Link> | <Link
              to="/users"
              hidden={!AuthUtil.userCanRead(Resource.USERS)}>{t('Header.userAccounts')} |</Link> <Link
              to="/groups" hidden={!AuthUtil.userCanRead(Resource.GROUPS)}>{t('Header.groups')} |</Link> <Link
              to="/templates"
              hidden={!AuthUtil.userCanRead(Resource.TEMPLATES)}>{t('Header.templates')} |</Link> <a
              href="#logout"
              onClick={logout}>{t('Header.logout')}</a>
          </div>
        </div>
      </div>
      <div className="container-fluid header-buttons">
        <div className="row justify-content-center">
          {!campaignsReadonly &&
          <div className="col-auto px-3" hidden={!AuthUtil.userCanCreate(Resource.CAMPAIGNS)}>
            <img src="/img/uusi-kampanja.png" className="img-fluid px-3" alt="newCampaign"/>
            <Link to="/create">{t('Header.newCampaign')}</Link>
          </div>}
          <div className="col-auto px-3" hidden={!AuthUtil.userCanRead(Resource.CAMPAIGNS)}>
            <img src="/img/kampanjat.png" className="img-fluid px-3" alt="listCampaigns"/>
            <Link to="/list">{t('Header.campaigns')}</Link>
          </div>
          <div className="col-auto px-3" hidden={!AuthUtil.userCanRead(Resource.REPORTS)}>
            <img src="/img/raportointi.png" className="img-fluid px-3" alt="reporting"/>
            <Link to="/reporting">{t('Header.reporting')}</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
