import Keycloak from 'keycloak-js';

const realm = process.env.REACT_APP_AUTH_REALM;
const clientId = process.env.REACT_APP_AUTH_CLIENTID;
const url = process.env.REACT_APP_AUTH_URL;

const keycloakConfig = {
  url: url,
  realm: realm,
  clientId: clientId,
  enableLogging: true,
  onLoad: 'login-required',
  loginRequired: true,
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
