import {IGroupModel} from '../usermanagement/IGroupModel';
import {IUserModel} from '../usermanagement/IUserModel';
import {ITemplateModel} from '../template/ITemplateModel';
import DateUtils from '../../utils/DateUtils';

export enum CampaignState {
  ACTIVE = 'Active',
  NOT_STARTED = 'NotStarted',
  FINISHED = 'Finished',
  ATTACHED = 'Attached',
  ARCHIVED = 'Archived',
  STARTING = 'Starting',
  ANY = 'Any',
}

export interface ICampaignModel extends ITemplateModel {
  startDate?: string,
  endDate?: string,
  permanent: boolean,
  archived: boolean,
  groups: IGroupModel[],
  users: IUserModel[],
  userIds?: number[],
  state: CampaignState,
}


export const campaignModelEmpty: ICampaignModel = {
  name: '',
  id: 0,
  valuePerUser: 0,
  smsSender: '',
  smsMessage: '',
  title: '',
  description: '',
  buttonText: '',
  customerContact: '',
  logoUrl: '',
  bannerUrl: '',
  qrCodeUrl: '',
  startDate: '',
  endDate: DateUtils.getStringFromDate(new Date()),
  permanent: false,
  archived: false,
  groups: [],
  users: [],
  state: CampaignState.ANY,
};
