import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import SelectSearch, {fuzzySearch, SelectSearchOption} from 'react-select-search';
import {fetchUsersPromise} from '../../utils/RestClient';
import {useKeycloak} from '@react-keycloak/web';
import {IUserModel} from '../../models/usermanagement/IUserModel';
import {handleError} from '../common/Error';
import AuthUtil, {Resource} from '../../utils/AuthUtil';

const UserSelectFilterComponent = ({
  addUser,
  users,
  removeUser,
  enabled = true,
}: { addUser: any, users: IUserModel[], removeUser: any, enabled?: boolean }) => {
  const {t} = useTranslation();
  const {keycloak} = useKeycloak();
  const [usersState, setUsersState] = useState<IUserModel[]>();
  const hasAccess = AuthUtil.userCanRead(Resource.USERS);

  function resolveUser(event: any) {
    const user: IUserModel | undefined = usersState?.filter((u: any) => u.id === event).pop();
    addUser(user);
  }

  async function fetchUserList(query?: string): Promise<SelectSearchOption[]> {
    if (hasAccess) {
      if (usersState && !query) {
        return mapUsersToSelectSearchOption(usersState);
      }
      return fetchUsersPromise(keycloak, query).catch(handleError).then((res: any) => {
        if (!query) {
          setUsersState(res?.data?.content);
        }
        return mapUsersToSelectSearchOption(res?.data?.content);
      });
    }
    return [];
  }

  function mapUsersToSelectSearchOption(groupArr: any): SelectSearchOption[] {
    return groupArr?.map((user: any) => {
      return {value: user.id, name: user.firstName + ' ' + user.lastName};
    }) ?? [];
  }

  return (<>
    <SelectSearch className="select-search" options={[]} search={true}
      filterOptions={fuzzySearch} id="userAdd" disabled={!enabled}
      placeholder={t('CreateGroup.findUser')} onChange={resolveUser}
      getOptions={fetchUserList}/>
    <table className="table-sm group-table">
      <tbody>
        {users?.map((u: IUserModel, index: number) => (
          <tr key={index}>
            <td>
              {u.firstName} {u.lastName}</td>
            <td>
              <button type="button"
                onClick={() => removeUser(u)} disabled={!enabled}
                className="btn btn-link">{t('Delete')}</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </>);
};


export default UserSelectFilterComponent;

