import * as React from 'react';
import {useState} from 'react';
import {fetchRoles, fetchUsers} from '../../utils/RestClient';
import {useTranslation} from 'react-i18next';
import {IUserModel, userModelEmpty} from '../../models/usermanagement/IUserModel';
import ListUsers from './ListUsers';
import CreateUser from './CreateUser';
import Error from '../common/Error';
import Loading from '../common/Loading';
import {Modal} from 'bootstrap';
import {emptyPageable} from '../../models/common/IPageable';
import Paging from '../common/Paging';

const UserManagementView = () => {
  const {t} = useTranslation();
  const [userSearch, setUserSearch] = useState('');
  const [page, setPage] = useState(emptyPageable);
  const {data, error, isLoading} = fetchUsers(userSearch, page);
  const {data: roles} = fetchRoles();
  const [modal, setModal] = useState<Modal>();
  const [user, setUser] = useState<IUserModel>(userModelEmpty);

  if (isLoading && !data.content) {
    return (<Loading/>);
  }
  if (error) {
    return (<Error message={t('error.fetch') + ' (' + error.message + ') '}/>);
  }

  function changePage(pageNbr: number) {
    setPage({...page, pageNumber: pageNbr});
  }

  function editUser(userEvent: IUserModel) {
    setUser({...userModelEmpty, ...userEvent});
    modal!.show();
  }

  function onUserSearch(event: any) {
    const searchTerm = event.target.value;
    if (searchTerm && searchTerm.length > 2) {
      setUserSearch(searchTerm);
      changePage(0);
    } else if (searchTerm.length == 0) {
      setUserSearch('');
      changePage(0);
    }
  }

  return (
    <div className="container-fluid">
      <div className="container-sm p-2">
        <div className="row">
          <div className="col">
            <h1 id="top-header" className="text-center">{t('UserManagement.UserAccounts')}</h1>
          </div>
        </div>
        <div className="row w-100">
          <div className="col py-5">
            <button type="button" onClick={() => editUser(userModelEmpty)} className="btn btn-primary">
              {t('UserManagement.CreateUser')}
            </button>
            <CreateUser userModel={user} modal={modal} setModal={setModal} allRoles={roles || []}/>
          </div>
        </div>
        <div className="row w-100">
          <div className="col-8">
            <h2>{t('UserManagement.UserAccounts')}</h2>
          </div>
          <div className="col col-auto me-auto">
            <label htmlFor="userSearch"
              className="col-form-label">  {t('UserManagement.Search')}</label>
          </div>
          <div className="col">
            <input type="text" id="userSearch" name="userSearch" className="form-control"
              onChange={onUserSearch}/>
          </div>
        </div>
        <div className="row">
          <ListUsers models={data.content || []} editUser={editUser}/>
        </div>
        <Paging changePage={changePage} itemCount={data.totalElements || 0} currentPage={page.pageNumber}/>
      </div>
    </div>);
};

export default UserManagementView;
