import * as React from 'react';
import type {RouteProps} from 'react-router-dom';
import {Redirect, Route} from 'react-router-dom';
import AuthUtil, {Role} from '../../../utils/AuthUtil';
import {KeycloakInstance} from 'keycloak-js';
import {Views} from './AppRouter';

interface PrivateRouteParams extends RouteProps {
  allowedRoles: Role[],
  keycloak: KeycloakInstance,
}

export function PrivateRoute({
  ...rest
}: PrivateRouteParams) {
  if (rest.keycloak?.authenticated) {
    if (AuthUtil.hasRole(rest.allowedRoles)) {
      return <Route {...rest} />;
    } else {
      return <Redirect to={{pathname: getDefaultView()}}/>;
    }
  } else {
    return <Redirect to={{pathname: '/login'}}/>;
  }
}

function getDefaultView(): string {
  if (AuthUtil.hasRole([Role.ROLE_GUEST])) {
    return Views.reporting;
  } else {
    return '/';
  }
}


