import * as React from 'react';
import {Suspense, useState} from 'react';
import {ReactKeycloakProvider} from '@react-keycloak/web';
import keycloak from './utils/keycloak';
import {AppRouter} from './components/common/router/AppRouter';
import {positions, Provider as AlertProvider} from 'react-alert';
// @ts-ignore
import AlertTemplate from 'react-alert-template-basic';

const App = () => {
  const [keyCloakError, setKeyCloakError] = useState();
  const eventLogger = (event: unknown, error: any) => {
    if (error) {
      console.log('onKeycloakEvent', event, error);
      setKeyCloakError(error.error);
    }
  };

  return (
    <AlertProvider template={AlertTemplate} position={positions.MIDDLE} timeout={10000} title="Virhe"
      offset="50px">
      <ReactKeycloakProvider authClient={keycloak} onEvent={eventLogger}>
        <Suspense fallback="{Loading}">
          <AppRouter keyCloakError={keyCloakError}/>
        </Suspense>
      </ReactKeycloakProvider>
    </AlertProvider>
  );
};
export default App;
