/* eslint object-curly-spacing: "off" */

import * as React from 'react';
import { useEffect, useState } from 'react';
import { ICampaignModel } from '../models/campaign/ICampaignModel';
import { CouponState, ICouponModel } from '../models/report/IReportModel';
import { useStateFromProp } from '../components/common/StateHelper';
import DateUtil from '../utils/DateUtils';

const Coupon = ({
  campaign,
  coupon,
  changeCouponState,
  endTime = new Date(),
  counterSeconds = 300,
}: {
  campaign: ICampaignModel;
  coupon: ICouponModel;
  changeCouponState: any;
  endTime?: Date;
  counterSeconds?: number;
}) => {
  const [counter, setCounter] = useState(counterSeconds);
  const [showActivate, setShowActivate] = useState(false);
  const [couponState] = useStateFromProp(coupon);
  const countdown = Math.floor((endTime.getTime() - new Date().getTime()) / 1000);

  useEffect(() => {
    if (couponState.state == CouponState.ACTIVATED) {
      if (countdown > 0) {
        const timer = setTimeout(() => {
          setCounter(countdown - 1);
        }, 1000);
        return () => clearTimeout(timer);
      } else if (counter == 0 || countdown == 0) {
        changeCouponState(CouponState.USED);
      }
    }
  }, [counter, coupon, couponState]);

  const minutes = Math.floor(counter / 60);
  let seconds: string = String(counter - minutes * 60);
  if (seconds.length < 2) {
    seconds = '0' + seconds;
  }

  const activateCode = () => {
    changeCouponState(CouponState.ACTIVATED);
  };

  return (
    <div id="coupon" className="container-sm coupon">
      <div className="row">
        <img src={campaign.logoUrl} className="logo" alt="logo image" />
      </div>
      <div className="row" hidden={couponState.state == CouponState.ACTIVATED}>
        <img src={campaign.bannerUrl} className="banner" alt="banner image" />
      </div>
      <div className="row" hidden={couponState.state != CouponState.ACTIVATED}>
        <img src={campaign.qrCodeUrl} className="qrcode" alt="qrcode image" />
      </div>
      <div className="row info text-center"><b>{campaign.title}</b></div>
      <div className="row text-center"><span>{campaign.description}</span></div>
      <div className="row text-center">
        <b>KOODISI:</b>
        <input
          className="form-control form-control-lg text-center"
          type="text"
          placeholder={couponState.code || 'XYZF-1234'}
          disabled={true}
        />
      </div>
      {coupon.expiredTimestamp && <div className="row text-center">
        <b>VOIMASSA:</b>
        <input
          className="form-control form-control-lg text-center"
          type="text"
          placeholder={DateUtil.getStringFromDate(coupon.expiredTimestamp)}
          disabled={true}
        />
      </div>
      }
      <div className="row text-center">
        <div
          className="alert alert-success"
          role="alert"
          id="timer-box"
          hidden={couponState.state != CouponState.ACTIVATED}
        >
          NÄYTÄ KASSALLA | 0{minutes}:{seconds}
        </div>
        <div className="alert alert-secondary" role="alert" hidden={couponState.state != CouponState.USED}>
          KOODI KÄYTETTY
        </div>
        <button
          type="button"
          className="btn btn-primary info bg-black"
          onClick={() => setShowActivate(true)}
          hidden={showActivate || couponState.state == CouponState.ACTIVATED || couponState.state == CouponState.USED}
        >
          AKTIVOI JA NÄYTÄ KASSALLA
        </button>
        <button
          type="button"
          className="btn btn-warning info"
          onClick={activateCode}
          hidden={couponState.state == CouponState.ACTIVATED || couponState.state == CouponState.USED || !showActivate}
        >
          KÄYTETÄÄNKÖ KOODI?
        </button>
      </div>
      <div className="row text-center">
        <div className="col text-center">
          {campaign.buttonText || 'Aktivoimisen jälkeen koodi on mahdollista näyttää kassalla viiden minuutin ajan'}
        </div>
      </div>
      <div className="row">
        <div className="col text-center small" style={{ whiteSpace: 'pre-wrap' }}>
          {campaign.customerContact}
        </div>
      </div>
    </div>
  );
};
export default Coupon;
