import * as React from 'react';
import {defaultPageSize} from '../../models/common/IPageable';

const Paging = ({
  itemCount,
  currentPage,
  changePage,
  pageSize = defaultPageSize,
}: { itemCount: number, currentPage: number, changePage: any, pageSize?: number }) => {
  let pages = 0;
  const fields: JSX.Element[] = [];
  if (itemCount) {
    pages = Math.ceil(itemCount / pageSize);
    if (pages > 1) {
      for (let i = 0; i < pages; i++) {
        fields.push(<li key={i} className={currentPage == i ? 'page-item active' : 'page-item'}><a
          className="page-link"
          onClick={() => changePage(i)}>{i + 1}</a></li>);
      }
    }
  }
  return (
    <div className="d-flex justify-content-center">
      <nav aria-label="pagination">
        <ul className="pagination">
          {fields}
        </ul>
      </nav>
    </div>
  );
};
export default Paging;
