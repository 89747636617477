import useSWR from 'swr';

import axios from 'axios';
import {CampaignState, ICampaignModel} from '../models/campaign/ICampaignModel';
import {useKeycloak} from '@react-keycloak/web';
import {IUserModel} from '../models/usermanagement/IUserModel';
import Keycloak, {KeycloakInstance} from 'keycloak-js';
import {IGroupModel} from '../models/usermanagement/IGroupModel';
import {IPageable} from '../models/common/IPageable';
import {ITemplateModel} from '../models/template/ITemplateModel';
import DateUtil from './DateUtils';

const fetcherWithToken = (url: string, token: string) => axios.get(url, {
  headers: {
    'Authorization': 'Bearer ' + token,
    'Content-Type': 'application/json',
  },
  data: {},
}).then((res) => res.data);

const fetcher = (url: string) => axios.get(url, {
  headers: {
    'Content-Type': 'application/json',
  },
  data: {},
}).then((res) => res.data);


const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const API_URL_USERS = 'users';
const API_URL_CAMPAIGNS = 'campaigns';
const API_URL_GROUPS = 'groups';
const API_URL_ROLES = 'roles';
const API_URL_TEMPLATES = 'templates';
const API_URL_REPORTS = 'reports/summary';
const API_URL_REPORTS_CSV = 'reports/download';
const API_URL_COUPONS_SEND = 'coupons/send/';
const API_URL_COUPONS = 'coupons/';
const API_URL_COUPONS_CODE = 'coupons/code/';
const API_URL_COUPONS_ACTIVATE = 'coupons/activate/';

export function fetchCurrentUser(keycloak: KeycloakInstance): Promise<any> {
  return axios.get(API_URL + 'current', {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    }, data: {},
  });
}

function createSearchString(userSearch?: string): string {
  let url = '';
  if (userSearch) {
    const searchString = encodeURI(userSearch);
    url += 'searchText=' + searchString;
  }
  return url;
}

function createUserSearchString(userSearch?: string, searchParamName: string = 'userSearch'): string {
  let url = '';
  if (userSearch) {
    const searchString = encodeURI(userSearch);
    url += searchParamName + '=' + searchString + '&groupSearch=' + searchString;
  }
  return url;
}

function createPageableQueryString(pageable?: IPageable): string {
  let result = '';
  if (pageable) {
    result += 'page=' + pageable.pageNumber + '&size=' + pageable.pageSize;
  }
  return result;
}

function createSortQueryString(pageable?: IPageable): string {
  let result = '';
  if (pageable && pageable.sort && pageable.sortDirection) {
    result += 'sort=' + pageable.sort + ',' + pageable.sortDirection;
  }
  return result;
}


function createUserGroupSearchString(groupSearch?: number) {
  if (groupSearch) {
    return 'groupIds=' + groupSearch;
  }
  return '';
}

function createUserQueryString(userSearch?: string, pageable?: IPageable, groupSearch?: number): string {
  const query = [createUserSearchString(userSearch), createPageableQueryString(pageable), createUserGroupSearchString(groupSearch)];
  return concatUrlAndParams(API_URL + API_URL_USERS, query);
}

function createCampaignQueryString(pageable?: IPageable, search?: string, state?: CampaignState, groups?: IGroupModel[]): string {
  const query = [createPageableQueryString(pageable), createSortQueryString(pageable), createSearchString(search), createCampaignStateQuery(state)];
  if (groups) {
    for (const group of groups) {
      query.push(createUserGroupSearchString(group.id));
    }
  }
  return concatUrlAndParams(API_URL + API_URL_CAMPAIGNS, query);
}

function createReportQueryString(url: string, pageable?: IPageable, search?: string, state?: CampaignState, groups?: IGroupModel[]): string {
  const query = [createPageableQueryString(pageable), createSortQueryString(pageable), createUserSearchString(search, 'search'), createCampaignStateQuery(state)];
  if (groups) {
    for (const group of groups) {
      query.push(createUserGroupSearchString(group.id));
    }
  }
  return concatUrlAndParams(url, query);
}


function createCampaignStateQuery(state?: CampaignState): string {
  let result = '';
  const date = DateUtil.getStringFromDate(new Date());
  switch (state) {
    case CampaignState.ATTACHED:
      result = 'fixedCampaigns=true';
      break;
    case CampaignState.NOT_STARTED:
    case CampaignState.STARTING:
      result = 'date=' + date + '&relation=Before';
      break;
    case CampaignState.FINISHED:
      result = 'date=' + date + '&relation=After';
      break;
    case CampaignState.ACTIVE:
      result = 'date=' + date + '&relation=Ongoing';
      break;
    case CampaignState.ARCHIVED:
      result = 'date=' + date + '&relation=Archived';
      break;
    case CampaignState.ANY:
      result = '';
      break;
  }
  return result;
}

function concatUrlAndParams(url: string, params: string[]): string {
  let result = url;
  for (const value of params) {
    if (value) {
      if (result.indexOf('?') > -1) {
        result += '&' + value;
      } else {
        result += '?' + value;
      }
    }
  }
  return result;
}

export function fetchUsers(userSearch?: string, pageable?: IPageable): any {
  const {keycloak} = useKeycloak();

  const {data, error} = useSWR([createUserQueryString(userSearch, pageable), keycloak.token], fetcherWithToken, {
    shouldRetryOnError: false,
    fallbackData: [],
  });
  return {
    data: data,
    error: error,
    isLoading: !data && !error,
  };
}

export function fetchUsersPromise(keycloak: KeycloakInstance, userSearch?: string, pageable?: IPageable, groupSearch?: number): Promise<any> {
  return axios.get(createUserQueryString(userSearch, pageable, groupSearch), {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    }, data: {},
  });
}

export function updateUser(user: IUserModel, keycloak: Keycloak.KeycloakInstance) {
  return axios.put(API_URL + API_URL_USERS + '/' + user.id, user, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    },
  });
}

export function createUser(user: IUserModel, keycloak: Keycloak.KeycloakInstance) {
  return axios.post(API_URL + API_URL_USERS, user, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    },
  });
}


export function removeUser(user: IUserModel, keycloak: Keycloak.KeycloakInstance): Promise<any> {
  return axios.delete(API_URL + API_URL_USERS + '/' + user.id, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    }, data: [],
  });
}

export function fetchCampaigns(page: IPageable, search?: string, state?: CampaignState, groups?: IGroupModel[]): any {
  const {keycloak} = useKeycloak();
  const {
    data, error,
  } = useSWR([createCampaignQueryString(page, search, state, groups), keycloak.token], fetcherWithToken, {
    shouldRetryOnError: false,
    fallbackData: [],
  });
  return {
    data: data,
    error: error,
    isLoading: !data && !error,
  };
}

export function createCampaign(campaign: ICampaignModel, keycloak: Keycloak.KeycloakInstance, formData: FormData) {
  return axios.post(API_URL + API_URL_CAMPAIGNS, formData, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function updateCampaign(campaign: ICampaignModel, keycloak: Keycloak.KeycloakInstance, formData: FormData) {
  return axios.put(API_URL + API_URL_CAMPAIGNS + '/' + campaign.id, formData, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function archiveCampaign(campaignId: number, keycloak: Keycloak.KeycloakInstance) {
  return axios.put(API_URL + API_URL_CAMPAIGNS + '/' + campaignId + '/archive', {archive: true}, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    },
  });
}

export function restoreCampaign(campaignId: number, keycloak: Keycloak.KeycloakInstance) {
  return axios.put(API_URL + API_URL_CAMPAIGNS + '/' + campaignId + '/restore', {archive: true}, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    },
  });
}

export function fetchRoles(): any {
  const {keycloak} = useKeycloak();
  const {data, error} = useSWR([API_URL + API_URL_ROLES, keycloak.token], fetcherWithToken, {
    shouldRetryOnError: false,
    fallbackData: [],
  });
  return {
    data: data,
    error: error,
    isLoading: !data && !error,
  };
}

export function fetchGroups(): any {
  const {keycloak} = useKeycloak();
  const {data, error} = useSWR([API_URL + API_URL_GROUPS, keycloak.token], fetcherWithToken, {
    shouldRetryOnError: false,
    fallbackData: [],
  });
  return {
    data: data,
    error: error,
    isLoading: !data && !error,
  };
}

export function fetchGroupsPromise(keycloak: KeycloakInstance, search?: string): Promise<any> {
  let url = API_URL + API_URL_GROUPS;
  if (search) {
    url += '?search=' + encodeURI(search);
  }
  return axios.get(url, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    }, data: {},
  });
}

export function fetchTemplatesPromise(keycloak: KeycloakInstance, search?: string): Promise<any> {
  let url = API_URL + API_URL_TEMPLATES;
  if (search) {
    url += '?searchText=' + encodeURI(search);
  }
  return axios.get(url, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    }, data: {},
  });
}

function updateGroupUserIds(group: IGroupModel): IGroupModel {
  if (group.users) {
    group.userIds = [];
    for (const u of group.users) {
      group.userIds.push(+u.id);
    }
  }
  return group;
}

export function createGroup(group: IGroupModel, keycloak: Keycloak.KeycloakInstance) {
  return axios.post(API_URL + API_URL_GROUPS, updateGroupUserIds(group), {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    },
  });
}

export function updateGroup(group: IGroupModel, keycloak: Keycloak.KeycloakInstance) {
  return axios.put(API_URL + API_URL_GROUPS + '/' + group.id, updateGroupUserIds(group), {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    },
  });
}

export function removeGroup(group: IGroupModel, keycloak: Keycloak.KeycloakInstance) {
  return axios.delete(API_URL + API_URL_GROUPS +
    '/' + group.id, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    }, data: {},
  },
  );
}

export function fetchTemplates(page: IPageable): any {
  const {keycloak} = useKeycloak();
  const {
    data,
    error,
  } = useSWR([API_URL + API_URL_TEMPLATES + '?' + createPageableQueryString(page), keycloak.token], fetcherWithToken, {
    shouldRetryOnError: false,
    fallbackData: [],
  });
  return {
    data: data,
    error: error,
    isLoading: !data && !error,
  };
}

export function createTemplate(templateModel: ITemplateModel, keycloak: Keycloak.KeycloakInstance, formData: FormData) {
  return axios.post(API_URL + API_URL_TEMPLATES, formData, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function updateTemplate(templateModel: ITemplateModel, keycloak: Keycloak.KeycloakInstance, formData: FormData) {
  return axios.put(API_URL + API_URL_TEMPLATES + '/' + templateModel.id, formData, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function removeTemplate(templateModel: ITemplateModel, keycloak: Keycloak.KeycloakInstance) {
  return axios.delete(API_URL + API_URL_TEMPLATES +
    '/' + templateModel.id, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    }, data: {},
  },
  );
}

export function fetchReports(page: IPageable, search?: string, state?: CampaignState, groups?: IGroupModel[]): any {
  const {keycloak} = useKeycloak();
  const {
    data,
    error,
  } = useSWR([createReportQueryString(API_URL + API_URL_REPORTS, page, search, state, groups), keycloak.token], fetcherWithToken, {
    shouldRetryOnError: false,
    fallbackData: [],
  });
  return {
    data: data,
    error: error,
    isLoading: !data && !error,
  };
}

export function fetchReportsCSV(keycloak: KeycloakInstance, page: IPageable, search?: string, state?: CampaignState, groups?: IGroupModel[]): any {
  return axios.get(createReportQueryString(API_URL + API_URL_REPORTS_CSV, page, search, state, groups), {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    }, data: {},
  });
}


export function fetchCouponsPerReport(campaignId: number, page: IPageable): any {
  const {keycloak} = useKeycloak();
  const {
    data,
    error,
  } = useSWR([API_URL + API_URL_COUPONS + campaignId + '?' + createPageableQueryString(page), keycloak.token], fetcherWithToken, {
    shouldRetryOnError: false,
    fallbackData: [],
  });
  return {
    data: data,
    error: error,
    isLoading: !data && !error,
  };
}

export function getMessageMaxLength(keycloak: Keycloak.KeycloakInstance): Promise<any> {
  const url = API_URL + API_URL_COUPONS + 'messagesize';
  return axios.get(url, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    }, data: {},
  });
}

export function fetchCouponWithToken(token: string): any {
  const {
    data,
    error,
    mutate,
  } = useSWR([API_URL + API_URL_COUPONS_CODE + token], fetcher, {
    shouldRetryOnError: false,
  });
  return {
    data: data,
    error: error,
    mutate: mutate,
    isLoading: !data && !error,
  };
}

export function activateCouponWithToken(token: string): any {
  return axios.put(API_URL + API_URL_COUPONS_ACTIVATE + token, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}


export function sendOneMessage(campaignId: number, phoneNumber: string, expiration: string, keycloak: Keycloak.KeycloakInstance) {
  const formData = new FormData();
  formData.append('phone_numbers', new Blob([JSON.stringify([phoneNumber])], {type: 'application/json'}));
  formData.append('expiration_date', expiration);
  return axios.post(API_URL + API_URL_COUPONS_SEND + campaignId, formData, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    },
  },
  );
}

export function sendMessages(campaignId: number, csvFile: any, expiration: string, keycloak: Keycloak.KeycloakInstance) {
  const formData = new FormData();
  formData.append('csv_file', csvFile);
  formData.append('expiration_date', expiration);
  return axios.post(API_URL + API_URL_COUPONS_SEND + campaignId, formData, {
    headers: {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json',
    },
  },
  );
}
