import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ITemplateModel} from '../../models/template/ITemplateModel';
import {fetchTemplates, removeTemplate} from '../../utils/RestClient';
import Loading from '../common/Loading';
import Error, {handleError} from '../common/Error';
import {useHistory} from 'react-router-dom';
import {confirmAlert} from 'react-confirm-alert';
import {useKeycloak} from '@react-keycloak/web';
import {Views} from '../common/router/AppRouter';
import {emptyPageable, IPageable} from '../../models/common/IPageable';
import Paging from '../common/Paging';
import AuthUtil, {Resource} from '../../utils/AuthUtil';

const TemplatesView = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const {keycloak} = useKeycloak();
  const [page, setPage] = useState<IPageable>(emptyPageable);
  const {data, error, isLoading} = fetchTemplates(page);

  if (isLoading && !data.content) {
    return (<Loading/>);
  }
  if (error) {
    return (<Error message={t('error.fetch') + ' (' + error.message + ') '}/>);
  }

  function changePage(pageNbr: number) {
    setPage({...page, pageNumber: pageNbr});
  }

  function deleteTemplate(template: ITemplateModel) {
    confirmAlert({
      title: t('ConfirmAction'),
      message: t('ConfirmDeleteMessage'),
      buttons: [
        {
          label: t('Yes'),
          onClick: () => {
            removeTemplate(template, keycloak).then((res) => {
              console.log(res);
              reload();
            }).catch(handleError);
          },
        },
        {
          label: t('Cancel'),
          onClick: () => console.log('Cancel'),
        },
      ],
    });
  }

  function reload() {
    history.push(Views.reload);
    history.goBack();
  }

  function editTemplate(template: ITemplateModel) {
    console.log(template);
    history.push(Views.createTemplate, {template: template});
  }

  return (
    <div className="container-fluid">
      <div className="container-sm p-2">
        <h1 id="top-header" className="text-center">{t('Templates.header')}</h1>
        <div className="row w-100">
          <div className="col py-5" hidden={!AuthUtil.userCanCreate(Resource.TEMPLATES)}>
            <button type="button" onClick={() => history.push(Views.createTemplate)}
              className="btn btn-primary">
              {t('Templates.create')}
            </button>
          </div>
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">{t('Templates.col.name')}</th>
              <th scope="col">{t('CreateCampaign.name')}</th>
              <th scope="col">{t('CreateCampaign.value')}</th>
            </tr>
          </thead>
          <tbody>
            {
              data.content?.map((item: ITemplateModel) => (
                <tr key={item.id}>
                  <td>{item.campaignName}</td>
                  <td>{item.name}</td>
                  <td>{item.valuePerUser}</td>
                  <td className="text-end">
                    <button type="button" className="btn btn-danger"
                      hidden={!AuthUtil.userCanCreate(Resource.TEMPLATES)}
                      onClick={() => deleteTemplate(item)}>{t('Delete')}
                    </button>
                  &nbsp;
                    <button type="button" className="btn btn-primary"
                      hidden={!AuthUtil.userCanCreate(Resource.TEMPLATES)}
                      onClick={() => editTemplate(item)}>{t('Edit')}
                    </button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <Paging changePage={changePage} itemCount={data.totalElements || 0} currentPage={page.pageNumber}
        pageSize={page.pageSize}/>
    </div>
  );
};
export default TemplatesView;
