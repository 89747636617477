import * as React from 'react';
import {useState} from 'react';
import {ICampaignModel} from '../../models/campaign/ICampaignModel';
import {useTranslation} from 'react-i18next';
import {sendMessages, sendOneMessage} from '../../utils/RestClient';
import {useKeycloak} from '@react-keycloak/web';
import {handleError, handleSendError} from '../common/Error';
import {AlertManager, useAlert} from 'react-alert';
// import DateUtil from '../../utils/DateUtils';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, {registerLocale} from 'react-datepicker';
import fi from 'date-fns/locale/fi';
import DateUtil, {DefaultDate} from '../../utils/DateUtils';

enum ButtonStatus {
  NONE,
  CONFIRM,
  SENDING,
  DONE,
  ERROR
}

const CampaignSendMessages = ({campaign}: { campaign: ICampaignModel }) => {
  const {t} = useTranslation();
  const [phoneNbr, setPhoneNbr] = useState<string>('');
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>(ButtonStatus.NONE);
  const [csvButtonStatus, setCsvButtonStatus] = useState<ButtonStatus>(ButtonStatus.NONE);
  const [csvFile, setCsvFile] = useState<File>();
  const {keycloak} = useKeycloak();
  const alert: AlertManager = useAlert();
  const {i18n} = useTranslation();
  const [csvExpiration, setCsvExpiration] = useState('');
  const [expiration, setExpiration] = useState('');

  registerLocale('fi', fi);

  const sendMessage = () => {
    console.log('Sending single message', phoneNbr, campaign.id);
    if (campaign.id > 0 && phoneNbr.length > 6) {
      sendOneMessage(campaign.id, phoneNbr, expiration, keycloak).then((res) => {
        console.log(res);
        setButtonStatus(ButtonStatus.DONE);
      }).catch((e) => {
        handleError(e, alert, t);
      }).finally(() => {
        hideStatus();
      });
    } else {
      // TODO show error, use Alert
      console.log('Did not send message', campaign.id, phoneNbr);
      hideStatus();
    }
  };
  const sendMessageCsv = () => {
    console.log('Send multiple messages', csvFile, campaign.id);
    if (campaign.id > 0 && csvFile) {
      sendMessages(campaign.id, csvFile, csvExpiration, keycloak).then((res) => {
        console.log(res);
        setCsvButtonStatus(ButtonStatus.DONE);
      }).catch((e) => {
        handleSendError(e, alert, t);
        setCsvButtonStatus(ButtonStatus.ERROR);
      }).finally(() => {
        hideStatus();
        const e: any = document.getElementById('csvFile');
        setCsvFile(undefined);
        if (e) {
          e.value = '';
        }
      });
    } else {
      // TODO show error, use Alert
      console.log('Did not send messages', campaign.id, csvFile);
      hideStatus();
    }
  };
  const hideStatus = () => {
    setTimeout(() => {
      setButtonStatus(ButtonStatus.NONE);
      setCsvButtonStatus(ButtonStatus.NONE);
    }, 3000);
  };

  const setExpirationDate = (date: Date, func: (date:string) => (void)) => {
    if (date.getHours() == 0 && date.getMinutes() == 0 && date.getSeconds() == 0) {
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
    }
    func(DateUtil.getStringFromDate(date));
  };

  return (
    <div className="container-sm my-5">
      <div className="row">
        <div className="col-sm-6">
          <div className="card p-5">
            <div className="card-body">
              <h5 className="card-title text-center">
                <div className="row text-center">
                  <i className="bi bi-people"></i>
                </div>
                {t('Campaigns.sendMultipleMessages')}
              </h5>
              <p className="card-text">{t('Campaigns.addCsv')}</p>
              <input className="form-control form-control-sm" id="csvFile" type="file" accept="text/csv,.csv"
                onChange={(event) => {
                  if (event.target.files) {
                    setCsvFile(event.target.files[0]);
                  } else {
                    setCsvFile(undefined);
                  }
                }}/>
              <p className="title">Syötä vanhentumispäivämäärä</p>
              <DatePicker
                selected={DateUtil.getDateFromString(csvExpiration, {date: null} as DefaultDate)}
                name="expiredDateCsv"
                minDate={new Date()}
                required={false}
                dateFormat="dd.MM.yyyy HH:mm"
                locale={i18n.language}
                showTimeSelect disabled={false}
                className="calendar"
                timeCaption={t('Time')}
                onChange={(date: Date) => {
                  setExpirationDate(date, setCsvExpiration);
                }}
              />
              <div className="p-5">
                <div className="row p-1">
                  <button className="btn btn-primary btn-lg" disabled={!csvFile}
                    hidden={csvButtonStatus != ButtonStatus.NONE}
                    onClick={() => setCsvButtonStatus(ButtonStatus.CONFIRM)}>Viestin lähetys
                  </button>
                </div>
                <div className="row p-1">
                  <button className="btn btn-warning btn-lg" onClick={() => {
                    setCsvButtonStatus(ButtonStatus.SENDING);
                    sendMessageCsv();
                  }}
                  hidden={csvButtonStatus != ButtonStatus.CONFIRM}>Lähetetäänkö viesti
                  </button>
                </div>
                <div className="row p-1">
                  <button className="btn btn-success btn-lg" hidden={csvButtonStatus != ButtonStatus.DONE}>Viesti
                    lähetetty
                  </button>
                </div>
                <div className="row p-1">
                  <button className="btn btn-danger btn-lg" hidden={csvButtonStatus != ButtonStatus.ERROR}>{t('error.error')}</button>
                </div>
                <div className="row p-1 text-center">
                  <div className="col">
                    <div className="spinner-border text-primary" role="status"
                      hidden={csvButtonStatus != ButtonStatus.SENDING}>
                      <span className="visually-hidden">Lähetetään viestejä...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card p-5">
            <div className="card-body">
              <h5 className="card-title text-center">
                <div className="row text-center">
                  <i className="bi bi-person"></i></div>
                Lähetä viesti yhdelle
                vastaanottajalle
              </h5>
              <p className="card-text">Syötä puhelinnumero</p>
              <input type="tel" className="form-control" id="phoneNbr"
                placeholder="+358 40 123 4567" onChange={(event) => setPhoneNbr(event.target.value)}
                defaultValue={phoneNbr}/>
              <p className="title">Syötä vanhentumispäivämäärä</p>
              <DatePicker selected={DateUtil.getDateFromString(expiration, {date: null} as DefaultDate)}
                name="expiredDateSingle"
                minDate={new Date()}
                required={false}
                dateFormat="dd.MM.yyyy HH:mm"
                locale={i18n.language}
                showTimeSelect disabled={false}
                className="calendar"
                timeCaption={t('Time')}
                onChange={(date: Date) => {
                  setExpirationDate(date, setExpiration);
                }}
              />
              <div className="p-5">
                <div className="row p-1">
                  <button className="btn btn-primary btn-lg" disabled={phoneNbr.length < 6}
                    hidden={buttonStatus != ButtonStatus.NONE}
                    onClick={() => setButtonStatus(ButtonStatus.CONFIRM)}>Viestin lähetys
                  </button>
                </div>
                <div className="row p-1">
                  <button className="btn btn-warning btn-lg" onClick={() => {
                    setButtonStatus(ButtonStatus.SENDING);
                    sendMessage();
                  }}
                  hidden={buttonStatus != ButtonStatus.CONFIRM}>Lähetetäänkö
                    viesti
                  </button>
                </div>
                <div className="row p-1">
                  <button className="btn btn-success btn-lg" hidden={buttonStatus != ButtonStatus.DONE}>Viesti
                    lähetetty
                  </button>
                </div>
                <div className="row p-1 text-center">
                  <div className="col">
                    <div className="spinner-border text-primary" role="status"
                      hidden={buttonStatus != ButtonStatus.SENDING}>
                      <span className="visually-hidden">Lähetetään viestejä...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};
export default CampaignSendMessages;
