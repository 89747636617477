import * as React from 'react';
import {IPageable} from '../../models/common/IPageable';
import {useStateFromProp} from './StateHelper';

const SortingTableHeader = ({
  label,
  pageable,
  columnId,
  changeSort,
}: { label: string, pageable?: IPageable, columnId?: string, changeSort?: any }) => {
  const [page] = useStateFromProp(pageable);

  function clicked() {
    if (page) {
      changeSort({sort: columnId, sortDirection: page.sortDirection == 'ASC' ? 'DESC' : 'ASC'});
    }
  }

  return (
    <th scope="col">
      <button className="btn-link border-0 bg-white fw-bold text-black text-decoration-none"
        onClick={() => clicked()} hidden={!page}>
        {label} <i className="bi bi-chevron-double-down"
          hidden={page?.sortDirection != 'DESC' || page.sort != columnId}/>
        <i className="bi bi-chevron-double-up" hidden={page?.sortDirection != 'ASC' || page.sort != columnId}/>
      </button>
      <span hidden={page}>{label}</span>
    </th>
  );
};
export default SortingTableHeader;
