import * as React from 'react';
import {useEffect, useRef} from 'react';
import {IUserModel} from '../../models/usermanagement/IUserModel';
import {Modal} from 'bootstrap';
import {useStateFromProp} from '../common/StateHelper';
import {createUser, removeUser, updateUser} from '../../utils/RestClient';
import {handleError} from '../common/Error';
import {useTranslation} from 'react-i18next';
import {useKeycloak} from '@react-keycloak/web';
import {useHistory} from 'react-router-dom';
import {IGroupModel} from '../../models/usermanagement/IGroupModel';
import {confirmAlert} from 'react-confirm-alert';
import GroupSelectFilterComponent from './GroupSelectFilterComponent';
import {IRoleModel} from '../../models/usermanagement/IRole';
import {Views} from '../common/router/AppRouter';
import {AlertManager, useAlert} from 'react-alert';
import AuthUtil from '../../utils/AuthUtil';

const CreateUser = ({
  userModel,
  modal,
  setModal, allRoles = [],
}: { userModel: IUserModel, modal: any, setModal: any, allRoles: IRoleModel[] }) => {
  const [userState, setUserState] = useStateFromProp(userModel);
  const [roleState, setRoleState] = useStateFromProp(userModel.roles);
  const [error, setError] = useStateFromProp(false);
  const {keycloak} = useKeycloak();
  const {t} = useTranslation();
  const userModal: any = useRef();
  const history = useHistory();
  const alert: AlertManager = useAlert();
  const isCreate = userState.id == 0;
  const allowedRoles = AuthUtil.allowedRoles();
  const roles = allRoles.filter((r) => allowedRoles.findIndex((r2) => r2 === r.name) >= 0);

  useEffect(() => {
    setModal(new Modal(userModal.current),
    );
  }, []);

  function handleSubmit(event: any) {
    event.preventDefault();
    if (!userState.roles || userState.roles.length == 0 || !userState.groups || userState.groups.length == 0) {
      setError(true);
      return;
    }
    if (isCreate) {
      console.log('Create user', userState);
      createUser(userState, keycloak).then((res) => {
        console.log(res);
        modal.hide();
        reload();
      }).catch((e) => {
        handleError(e, alert, t);
      });
    } else {
      console.log('Update user', userState);
      updateUser(userState, keycloak).then((res) => {
        console.log(res);
        modal.hide();
        reload();
      }).catch((e) => handleError(e, alert, t));
    }
  }

  function deleteUser() {
    modal.hide();
    confirmAlert({
      title: t('ConfirmAction'),
      message: t('ConfirmDeleteMessage'),
      buttons: [
        {
          label: t('Yes'),
          onClick: () => {
            removeUser(userState, keycloak).then((res) => {
              console.log(res);
              modal.hide();
              reload();
            }).catch(handleError);
          },
        },
        {
          label: t('Cancel'),
          onClick: () => {
            console.log('Cancel');
            modal.show();
          },
        },
      ],
    });
  }

  function reload() {
    history.push(Views.reload);
    history.goBack();
  }

  function handleCampaignsReadonlyChange(event: any) {
    const {name, value} = event.currentTarget;
    setUserState({...userState, [name]: value === 'false' ? true : false});
  }

  function handleChange(event: any) {
    const {name, value} = event.currentTarget;
    setUserState({...userState, [name]: value});
  }

  function removeGroup(group: IGroupModel) {
    const groups = userState.groups || [];
    const index = groups.indexOf(group, 0);
    if (index > -1) {
      groups.splice(index, 1);
      setUserState({...userState, groups: groups});
    }
  }

  function addGroup(group: IGroupModel) {
    const userGroups = userState.groups || [];
    if (!userGroups.some((g: IGroupModel) => g.id == group.id)) {
      userGroups.push(group);
      setUserState({...userState, groups: userGroups});
    }
  }

  function handleRoleChange(event: any) {
    const {value} = event.currentTarget;
    const role = allRoles.filter((r) => r.id == value)?.pop();
    if (event.currentTarget.checked && role) {
      roleState.push(role);
    } else {
      const indx = roleState.findIndex((a: any) => a.id == role?.id);
      if (indx > -1) {
        roleState.splice(indx, 1);
      }
    }
    setRoleState(roleState);
    setUserState({...userState, roles: roleState});
  }

  return (
    <div className="modal fade" ref={userModal} aria-labelledby="createUserModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title"
              id="createUserModalLabel">{isCreate ? t('UserManagement.CreateUser') : t('UserManagement.EditUser')}</h2>
            <button type="button" className="btn-close" onClick={() => modal!.hide()}
              aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="container-sm">
              <form onSubmit={handleSubmit} className="userForm">
                <div className="row">
                  <div className="col">
                    <label htmlFor="group" className="form-label">{t('CreateUser.groups')}</label>
                    <GroupSelectFilterComponent addGroup={addGroup} removeGroup={removeGroup}
                      groups={userState.groups}/>
                    <div className="invalid-feedback"
                      style={{display: error && userState.groups.length == 0 ? 'block' : 'none'}}>{t('Required')}
                    </div>
                  </div>
                  <div className="col">
                    <label htmlFor="firstName"
                      className="form-label">{t('CreateUser.firstName')}</label>
                    <input type="text" className="form-control" name="firstName"
                      value={userState.firstName} onChange={handleChange} required/>
                  </div>
                  <div className="col">
                    <label htmlFor="clientNbr"
                      className="form-label">{t('CreateUser.clientNbr')}</label>
                    <input type="text" className="form-control" name="customerRef"
                      value={userState.customerRef} onChange={handleChange}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {AuthUtil.isUser(userState.roles) ?
                      <div>
                        <label htmlFor="campaignsReadonly">{t('CreateUser.campaignsReadonly')}</label>
                        <input
                          className={'form-check-input'}
                          type="checkbox"
                          id={'campaignsReadonly'}
                          name="campaignsReadonly"
                          value={userState.campaignsReadonly}
                          checked={userState.campaignsReadonly} onChange={handleCampaignsReadonlyChange} />
                      </div> : <span />
                    }
                  </div>
                  <div className="col">
                    <label htmlFor="lastName"
                      className="form-label">{t('CreateUser.lastName')}</label>
                    <input id="lastName" type="text" className="form-control" name="lastName"
                      value={userState.lastName}
                      onChange={handleChange} required/>
                  </div>
                  <div className="col">
                    <label htmlFor="company"
                      className="form-label">{t('CreateUser.company')}</label>
                    <input id="company" type="text" className="form-control" name="billingCompany"
                      value={userState.billingCompany}
                      onChange={handleChange}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col"></div>
                  <div className="col-8">
                    <label htmlFor="email" className="form-label">{t('CreateUser.email')}</label>
                    <input id="email" type="text" className="form-control" name="email"
                      value={userState.email}
                      onChange={handleChange} required/>
                  </div>
                </div>
                <div className="row">
                  <div className="col"></div>
                  <div className="col">
                    <label htmlFor="phoneNumber"
                      className="form-label">{t('CreateUser.phoneNbr')}</label>
                    <input id="phoneNumber" type="text" className="form-control" name="phoneNumber"
                      value={userState.phoneNumber}
                      onChange={handleChange}/>
                  </div>
                  <div className="col">
                    <label htmlFor="role" className="form-label">{t('CreateUser.role')}</label>
                    <br/>
                    {
                      roles.map((item: any, idx) => (
                        <div className="form-check form-check-inline" key={item.id}>
                          <input
                            className={'form-check-input'}
                            type="checkbox"
                            id={'inlineCheckbox' + idx}
                            name="role" onChange={handleRoleChange}
                            value={item.id}
                            checked={roleState.filter((role: any) => role.name == item.name).length > 0}
                          /> {t('Role.' + item.name)}
                        </div>
                      ))
                    }
                    <div className="invalid-feedback"
                      style={{display: error && roleState.length == 0 ? 'block' : 'none'}}>{t('Required')}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col"/>
                  <div className="col"/>
                  <div className="col"/>
                </div>
                <div className="modal-footer">
                  {!isCreate &&
                    <button className="btn btn-danger" type="button" key="remove-user"
                      onClick={deleteUser}>{t('CreateUser.Remove')}
                    </button>
                  }
                  <button type="button" className="btn btn-secondary"
                    onClick={() => modal.hide()}>{t('Cancel')}
                  </button>
                  <button type="submit"
                    className="btn btn-primary">{t('CreateUser.Save')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
