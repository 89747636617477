import * as React from 'react';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ICouponModel, reportModelEmpty} from '../../models/report/IReportModel';
import {useTranslation} from 'react-i18next';
import DateUtil from '../../utils/DateUtils';
import {Views} from '../common/router/AppRouter';
import {fetchCouponsPerReport} from '../../utils/RestClient';
import {emptyPageable} from '../../models/common/IPageable';
import Paging from '../common/Paging';
import Loading from '../common/Loading';
import Error from '../common/Error';

const ViewReport = () => {
  const history = useHistory<any>();
  const {t} = useTranslation();
  if (!history.location.state?.report) {
    history.push(Views.reporting);
  }
  const [report] = useState(history.location.state?.report || reportModelEmpty);
  const [page, setPage] = useState(emptyPageable);
  const {data, error, isLoading} = fetchCouponsPerReport(report.id, page);
  if (isLoading && !data.content) {
    return (<Loading/>);
  }
  if (error) {
    return (<Error message={t('error.fetch') + ' (' + error.message + ') '}/>);
  }
  const headerClassName = 'col fw-bold';
  const valueClassName = 'col text-white p-3 align-content-center';

  function changePage(pageNbr: number) {
    setPage({...page, pageNumber: pageNbr});
  }

  return (
    <>
      <div className="container-sm">
        <div className="row mb-5">
          <h1 id="top-header" className="text-center">{t('Reporting.header')}</h1>
        </div>
        <div className="row mb-5">
          <h2 className="text-center">{report.name}</h2>
        </div>
        <div className="row mb-3">
          <div className={headerClassName}>{t('UserTable.col.ID')}</div>
          <div className={headerClassName}>{t('UserTable.col.group')}</div>
          <div className={headerClassName}>{t('CreateCampaign.name')}</div>
          <div className={headerClassName}>{t('CampaignTable.col.starttime')}</div>
          <div className={headerClassName}>{t('CampaignTable.col.endtime')}</div>
          <div className={headerClassName}>{t('CampaignTable.col.codeamount')}</div>
          <div className={headerClassName}>{t('CampaignTable.col.valuepercode')}</div>
          <div className={headerClassName}>{t('CampaignTable.col.totalvalue')}</div>
          <div className={headerClassName}>{t('Reporting.col.used')}</div>
          <div className={headerClassName}>{t('Reporting.col.unused')}</div>
        </div>
        <div className="row align-items-center bg-primary">
          <div className={valueClassName}>{report.id}</div>
          <div className={valueClassName}>{report.groupNames.toString()}</div>
          <div className={valueClassName}>{report.name}</div>
          <div className={valueClassName}>{DateUtil.getShortStringFromDate(report.startDate)}</div>
          <div className={valueClassName}>{DateUtil.getShortStringFromDate(report.endDate)}</div>
          <div className={valueClassName}>{report.couponCount}</div>
          <div className={valueClassName}>{report.pricePerUser}</div>
          <div className={valueClassName}>{Math.round(report.couponTotalPrice)}</div>
          <div className="col reporting-used-amount-col p-3 text-center">
            <span className="border-bottom border-dark d-block">{report.usedCouponCount || 0}</span>
            {Math.round(report.pricePerUser * report.usedCouponCount) || 0} €
          </div>
          <div className="col reporting-unused-amount-col p-3 text-center">
            <span
              className="border-bottom border-dark d-block">{report.unusedCouponCount || 0}</span>
            {Math.round(report.pricePerUser * report.unusedCouponCount) || 0} €
          </div>
        </div>
        <div className="row mt-5">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">{t('Reporting.col.receiver')}</th>
                <th scope="col">{t('Reporting.col.code')}</th>
                <th scope="col">{t('Reporting.col.status')}</th>
                <th scope="col">{t('Reporting.col.time')}</th>
                <th scope="col">{t('Reporting.col.expired.time')}</th>
              </tr>
            </thead>
            <tbody>
              {
                data.content?.map((item: ICouponModel) => (
                  <tr key={item.id}>
                    <td>{item.phoneNumber}</td>
                    <td>{item.code}</td>
                    <td>{t('couponStatus.' + item.status)}</td>
                    <td>{DateUtil.getStringFromDate(item.activatedTimestamp)}</td>
                    <td>{DateUtil.getStringFromDate(item.expiredTimestamp)}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div className="row align-items-center">
          <Paging changePage={changePage} itemCount={data.totalElements || 0} currentPage={page.pageNumber}
            pageSize={page.pageSize}/>
        </div>
        <div className="row mt-5 justify-content-end">
          <div className="col-md-1 offset-md-3">
            <button type="button" className="btn btn-primary" onClick={() => {
              history.push(Views.reporting);
            }}>{t('Exit')}</button>
          </div>
        </div>
      </div>
    </>);
};

export default ViewReport;
