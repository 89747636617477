import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Loading from '../common/Loading';
import Error, {handleError} from '../common/Error';
import {createGroup, fetchGroups, fetchUsersPromise, removeGroup, updateGroup} from '../../utils/RestClient';
import {groupModelEmpty, IGroupModel} from '../../models/usermanagement/IGroupModel';
import ListGroups from './ListGroups';
import CreateGroup from './CreateGroup';
import {Modal} from 'bootstrap';
import {useKeycloak} from '@react-keycloak/web';
import {useHistory} from 'react-router-dom';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Views} from '../common/router/AppRouter';
import AuthUtil, {Resource} from '../../utils/AuthUtil';
import {AlertManager, useAlert} from 'react-alert';


const GroupManagementView = () => {
  const {data, error, isLoading} = fetchGroups();
  const {t} = useTranslation();
  const [modal, setModal] = useState<Modal>();
  const {keycloak} = useKeycloak();
  const [group, setGroup] = useState<IGroupModel>(groupModelEmpty);
  const alert: AlertManager = useAlert();
  const history = useHistory();

  if (isLoading && !data.content) {
    return (<Loading/>);
  }
  if (error) {
    return (<Error message={t('error.fetch') + ' (' + error.message + ') '}/>);
  }

  function addGroup(group: IGroupModel) {
    if (group.id) {
      updateGroup(group, keycloak).then(() => {
        modal!.hide();
        reload();
      }).catch((e) => handleError(e, alert, t));
    } else {
      createGroup(group, keycloak).then(() => {
        modal!.hide();
        reload();
      }).catch((e) => handleError(e, alert, t));
    }
  }

  function deleteGroup(group: IGroupModel) {
    confirmAlert({
      title: t('ConfirmAction'),
      message: t('ConfirmDeleteMessage'),
      buttons: [
        {
          label: t('Yes'),
          onClick: () => {
            removeGroup(group, keycloak).then((res) => {
              console.log(res);
              reload();
            }).catch((e) => handleError(e, alert, t));
          },
        },
        {
          label: t('Cancel'),
          onClick: () => console.log('Cancel'),
        },
      ],
    });
  }

  function reload() {
    history.push(Views.reload);
    history.goBack();
  }

  function editGroup(group: IGroupModel) {
    if (group.id > 0) {
      fetchUsersPromise(keycloak, '', undefined, group.id).then((value) => {
        setGroup({...group, users: value.data.content});
      }).catch(handleError);
    }
    setGroup(group);
    modal!.show();
  }

  return (
    <div className="container-fluid">
      <div className="container-sm p-2">
        <div className="row">
          <div className="col">
            <h1 id="top-header" className="text-center">{t('UserManagement.Groups')}</h1>
          </div>
        </div>
        <div className="row w-100">
          <div className="col py-5">
            <button className="btn btn-primary" hidden={!AuthUtil.userCanCreate(Resource.GROUPS)}
              onClick={() => {
                editGroup(groupModelEmpty);
              }}>{t('UserManagement.CreateGroup')}
            </button>
            <CreateGroup group={group} addGroup={addGroup} modal={modal} setModal={setModal}/>
          </div>
        </div>
        <ListGroups models={data.content || []} editGroup={editGroup} removeGroup={deleteGroup}/>
      </div>
    </div>);
};
export default GroupManagementView;
