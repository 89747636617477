import * as React from 'react';


const BlankView = () => {
  return (<div className="container-sm vh-100 ">
    <div className="row h-100">
      <div className="col align-self-center text-center">
        <span style={{fontSize: '52px', fontWeight: 'bolder'}}>DIGIVIESTI<span
          className="text-primary">.fi</span></span>
      </div>
    </div>
  </div>);
};
export default BlankView;
