import {IUserModel} from './IUserModel';
import {IBaseModel} from '../common/IBaseModel';

export function groupArrayToString(arr?: IGroupModel[]): string {
  let result = '';
  if (arr) {
    arr.map((g, i) => {
      result += i == 0 ? ' ' + g.name : ', ' + g.name;
    });
  }
  return result;
}


export interface IGroupModel extends IBaseModel {
  name: string,
  version?: number,
  users: IUserModel[],
  userIds?: number[]
}

export const groupModelEmpty: IGroupModel = {
  id: 0,
  name: '',
  users: [],
};
