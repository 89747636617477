import * as React from 'react';
import {useState} from 'react';
import SelectSearch, {fuzzySearch, SelectSearchOption} from 'react-select-search';
import {fetchTemplatesPromise} from '../../utils/RestClient';
import {useKeycloak} from '@react-keycloak/web';
import {ITemplateModel} from '../../models/template/ITemplateModel';
import {useTranslation} from 'react-i18next';

const TemplateSelectComponent = ({
  selectTemplate,
}: { selectTemplate: any }) => {
  const {keycloak} = useKeycloak();
  const [templatesState, setTemplatesState] = useState<ITemplateModel[]>();
  const [template, setTemplate] = useState<ITemplateModel>();
  const {t} = useTranslation();

  function fetchTemplateList(query?: string): Promise<SelectSearchOption[]> {
    if (templatesState && !query) {
      return new Promise((resolve) => {
        resolve(mapTemplatesToSelectSearchOption(templatesState));
      });
    }
    return new Promise((resolve, reject) => {
      fetchTemplatesPromise(keycloak, query).then((res) => {
        if (!query) {
          setTemplatesState(res.data?.content);
        }
        resolve(mapTemplatesToSelectSearchOption(res.data?.content));
      }).catch(reject);
    });
  }

  function mapTemplatesToSelectSearchOption(templates: any): SelectSearchOption[] {
    return templates?.map((template: any) => {
      return {value: template.id, name: template.name};
    }) ?? [];
  }

  function resolveTemplate(groupId: any) {
    const template: any = templatesState?.find((g: any) => g.id == groupId);
    if (template) {
      setTemplate(template);
    }
  }

  return (<div className="row">
    <SelectSearch className="select-search" options={[]} search={true}
      filterOptions={fuzzySearch} id="templateSelect"
      placeholder="" onChange={resolveTemplate}
      getOptions={fetchTemplateList}/>
    <button className="btn btn-primary col-md-auto" onClick={(e) => {
      e.preventDefault();
      if (template) {
        selectTemplate(template);
      }
    }}>{t('Use')}</button>
  </div>);
};
export default TemplateSelectComponent;

