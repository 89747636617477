import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {IReportModel} from '../../models/report/IReportModel';
import {useHistory} from 'react-router-dom';
import {Views} from '../common/router/AppRouter';
import DateUtils from '../../utils/DateUtils';

const ReportTable = ({models}: { models: IReportModel[] }) => {
  const {t} = useTranslation();
  const history = useHistory();

  function view(report: IReportModel) {
    history.push(Views.reportView, {report: report});
  }

  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">{t('CampaignTable.col.ID')}</th>
          <th scope="col">{t('CampaignTable.col.client')}</th>
          <th scope="col">{t('CampaignTable.col.name')}</th>
          <th scope="col">{t('createDate')}</th>
          <th scope="col">{t('CampaignTable.col.starttime')}</th>
          <th scope="col">{t('CampaignTable.col.endtime')}</th>
          <th scope="col">{t('CampaignTable.col.codeamount')}</th>
          <th scope="col">{t('CampaignTable.col.valuepercode')}</th>
          <th scope="col">{t('CampaignTable.col.totalvalue')}</th>
          <th scope="col">{t('Reporting.col.used')}</th>
          <th scope="col">{t('Reporting.col.unused')}</th>
        </tr>
      </thead>
      <tbody>
        {
          models.map((item: IReportModel) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.groupNames?.toString()}</td>
              <td>{item.name}</td>
              <td>{DateUtils.getShortStringFromDate(item.createdDate)}</td>
              <td>{DateUtils.getStringFromDate(item.startDate)}</td>
              <td>{DateUtils.getStringFromDate(item.endDate)}</td>
              <td>{item.couponCount}</td>
              <td>{item.pricePerUser}</td>
              <td>{Math.round(item.couponTotalPrice)}</td>
              <td>{item.usedCouponCount}</td>
              <td>{item.unusedCouponCount}</td>
              <td>
                <button type="button" className="btn btn-primary"
                  onClick={() => view(item)}>{t('Reporting.view')}</button>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>);
};
export default ReportTable;
