import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useKeycloak} from '@react-keycloak/web';
import {CampaignState, ICampaignModel} from '../../models/campaign/ICampaignModel';
import {useHistory} from 'react-router-dom';
import {Views} from '../common/router/AppRouter';
import DateUtil from '../../utils/DateUtils';
import DateUtils from '../../utils/DateUtils';
import CouponPreviewModal from '../../mobile/CouponPreviewModal';
import {groupArrayToString} from '../../models/usermanagement/IGroupModel';
import SortingTableHeader from '../common/SortingTableHeader';
import {IPageable} from '../../models/common/IPageable';
import {archiveCampaign, restoreCampaign} from '../../utils/RestClient';
import {handleError} from '../common/Error';
import {AlertManager, useAlert} from 'react-alert';
import {useState} from 'react';

const CampaignTable = ({
  models,
  sort,
  changeSort,
  showStateCol,
  state,
}: { models: ICampaignModel[], sort?: IPageable, changeSort?: any, showStateCol?: boolean, state?: CampaignState }) => {
  const {t} = useTranslation();
  const history = useHistory();
  const {keycloak} = useKeycloak();
  const alert: AlertManager = useAlert();
  const [reload, setReload] = useState<boolean>(false);

  function classNameForState(campaignState: CampaignState): string {
    switch (campaignState) {
      case CampaignState.ACTIVE:
        return 'btn bg-success';
      case CampaignState.NOT_STARTED:
        return 'btn bg-warning';
      case CampaignState.FINISHED:
        return 'btn bg-secondary';
      case CampaignState.ATTACHED:
        return 'btn bg-info';
      case CampaignState.ARCHIVED:
        return 'btn bg-secondary';
    }
    return 'btn bg-primary';
  }

  function getCampaignState(campaign: ICampaignModel): CampaignState {
    if (campaign.permanent || !campaign.endDate) {
      return CampaignState.ATTACHED;
    }
    const today = new Date();
    const endDate: Date = DateUtil.getDateFromString(campaign.endDate);
    const startDate: Date = DateUtil.getDateFromString(campaign.startDate);

    if (endDate > today && startDate <= today) {
      return CampaignState.ACTIVE;
    }

    if (startDate > today) {
      return CampaignState.NOT_STARTED;
    }

    if (campaign.archived) {
      return CampaignState.ARCHIVED;
    }

    return CampaignState.FINISHED;
  }

  function view(campaign: ICampaignModel) {
    history.push(Views.viewCampaign, {campaign: campaign});
  }

  function archive(campaign: ICampaignModel) {
    archiveCampaign(campaign.id, keycloak).then(() => {
      campaign.archived = true;
      if (state && state != CampaignState.ARCHIVED) {
        models.splice(models.indexOf(campaign), 1);
      }
      setReload(!reload);
    }).catch((e) => handleError(e, alert, t));
  }

  function restore(campaign: ICampaignModel) {
    restoreCampaign(campaign.id, keycloak).then(() => {
      campaign.archived = false;
      models.splice(models.indexOf(campaign), 1);
      setReload(!reload);
    }).catch((e) => handleError(e, alert, t));
  }

  return (
    <table className="table table-sm">
      <thead>
        <tr>
          <SortingTableHeader label={t('CampaignTable.col.ID')} pageable={sort} columnId={'id'}
            changeSort={changeSort}/>
          <SortingTableHeader label={t('CampaignTable.col.client')} pageable={sort} columnId={'groups'}
            changeSort={changeSort}/>
          <SortingTableHeader label={t('CampaignTable.col.name')} pageable={sort} columnId={'name'}
            changeSort={changeSort}/>
          <SortingTableHeader label={t('createDate')} pageable={sort} columnId={'createdDate'}
            changeSort={changeSort}/>
          <SortingTableHeader label={t('CampaignTable.col.starttime')} pageable={sort} columnId={'startDate'}
            changeSort={changeSort}/>
          <SortingTableHeader label={t('CampaignTable.col.endtime')} pageable={sort} columnId={'endDate'}
            changeSort={changeSort}/>
          <th scope="col">{t('CampaignTable.col.codeamount')}</th>
          <SortingTableHeader label={t('CampaignTable.col.valuepercode')} pageable={sort} columnId={'valuePerUser'}
            changeSort={changeSort}/>
          <th scope="col">{t('CampaignTable.col.totalvalue')}</th>
          <th scope="col" hidden={!showStateCol}>{t('CampaignTable.col.state')}</th>
          <th scope="col" hidden={!showStateCol}>{t('CampaignTable.col.actions')}</th>
        </tr>
      </thead>
      <tbody>
        {
          models.map((item: ICampaignModel, idx: number) => (
            <tr key={idx}>
              <td>{item.id}</td>
              <td>{groupArrayToString(item.groups)}</td>
              <td>{item.name}</td>
              <td>{DateUtils.getShortStringFromDate(item.createdDate)}</td>
              <td>{item.startDate}</td>
              <td>{item.endDate}</td>
              <td>{item.couponCount}</td>
              <td>{item.valuePerUser}</td>
              <td>{item.couponTotalPrice}</td>
              <td hidden={!showStateCol}>
                <span
                  className={classNameForState(item.state = getCampaignState(item))}>{t('Campaigns.state.' + item.state)}</span>
              </td>
              {!item.archived ?
              <td>
                <button type="button" className="btn btn-primary" onClick={() => {
                  view(item);
                }}>{t('Open')}</button>
              </td> : <td></td>}
              <td>
                <CouponPreviewModal campaignParameter={item} btnLabel={t('View')}/>
              </td>
              {!item.archived && getCampaignState(item) == CampaignState.FINISHED ?
              <td>
                <button type="button" className="btn btn-primary" onClick={() => {
                  archive(item);
                }}>{t('Archive')}</button>
              </td> : state && state === CampaignState.ARCHIVED && item.archived ?
              <td><button type="button" className="btn btn-primary" onClick={() => {
                restore(item);
              }}>{t('Restore')}</button></td> : <td></td>}
            </tr>
          ))
        }
      </tbody>
    </table>);
};
export default CampaignTable;
