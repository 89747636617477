import {CampaignState} from '../../models/campaign/ICampaignModel';
import * as React from 'react';

const CampaignStateButton = ({
  filter,
  filterState,
  onFilter,
  label,
}: { filter: CampaignState, filterState: CampaignState, onFilter: any, label: string }) => {
  return (<button type="button" onClick={() => onFilter(filter)}
    className={filter == filterState ? 'btn btn-primary active' : 'btn btn-secondary'}>
    {label}
  </button>);
};
export default CampaignStateButton;
