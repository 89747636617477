import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import './i18n';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

ReactDOM.render(
    <App/>,
    document.getElementById('root'),
);
