import {IBaseModel} from '../common/IBaseModel';
import {IGroupModel} from '../usermanagement/IGroupModel';

export interface ITemplateModel extends IBaseModel {
  name: string,
  campaignName?: string,
  valuePerUser: number,
  smsSender: string,
  smsMessage: string,
  title: string,
  description: string,
  buttonText: string,
  customerContact?: string,
  logoUrl?: string,
  bannerUrl?: string,
  qrCodeUrl?: string,
  createdDate?: Date,
  groups: IGroupModel[],
  couponCount?: number,
  couponTotalPrice?: number,
  customerName?: string
}

export const templateModelEmpty: ITemplateModel = {
  id: 0,
  name: '',
  valuePerUser: 0,
  smsSender: '',
  smsMessage: '',
  title: '',
  description: '',
  buttonText: '',
  customerContact: '',
  logoUrl: '',
  bannerUrl: '',
  qrCodeUrl: '',
  groups: [],
};

