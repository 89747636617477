import * as React from 'react';
import {useKeycloak} from '@react-keycloak/web';

const Footer = ({params}: any) => {
  const {keycloak} = useKeycloak();
  if (!keycloak?.authenticated) {
    return (<></>);
  }
  return (
    <div className="text-center m-5 footer">
      <img src="/img/laitinen-logo-web.png" alt="laitinen-logo" className="mx-auto"/>
      <small>
        {params.name} <br/>
        {params.email} <br/>
        {params.phone} <br/>
        {params.address} <br/>
        {params.address2}
      </small>
    </div>
  );
};
export default Footer;
