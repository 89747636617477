import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {IGroupModel} from '../../models/usermanagement/IGroupModel';
import AuthUtil, {Resource} from '../../utils/AuthUtil';

const ListGroups = ({
  models,
  editGroup,
  removeGroup,
}: { models: IGroupModel[], editGroup: any, removeGroup: any }) => {
  const {t} = useTranslation();


  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">{t('GroupTable.col.name')}</th>
          <th scope="col">{t('GroupTable.col.amountOfUsers')}</th>
        </tr>
      </thead>
      <tbody>
        {
          models.map((item: IGroupModel) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.users?.length}</td>
              <td className="text-end">
                <button type="button" className="btn btn-danger"
                  hidden={!AuthUtil.userCanCreate(Resource.GROUPS)}
                  onClick={() => removeGroup(item)}>{t('Delete')}
                </button>
              &nbsp;
                <button type="button" className="btn btn-primary"
                  hidden={!AuthUtil.userCanCreate(Resource.GROUPS)}
                  onClick={() => editGroup(item)}>{t('Edit')}
                </button>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>);
};
export default ListGroups;

