import {CampaignState} from '../campaign/ICampaignModel';
import {IBaseModel} from '../common/IBaseModel';

export interface IReportModel extends IBaseModel {
  customerName: string,
  name: string,
  startDate: Date,
  endDate: Date,
  couponCount: number,
  pricePerUser: number,
  couponTotalPrice: number,
  usedCouponCount: number,
  unusedCouponCount: number,
  state: CampaignState;
  groupNames: string[],
  coupons?: ICouponModel[],
  createdDate?: Date
}

export enum CouponState {
  NEW,
  DELIVERED,
  ACTIVATED,
  USED,
  EXPIRED
}

export function getCouponState(coupon?: ICouponModel): CouponState {
  if (coupon?.status == 'Used') {
    return CouponState.USED;
  } else if (coupon?.status == 'Activated') {
    return CouponState.ACTIVATED;
  }
  return CouponState.NEW;
}


export interface ICouponModel extends IBaseModel {
  code: string,
  phoneNumber: string,
  status: string,
  state?: CouponState,
  messageId: string,
  deliveredTimestamp: Date,
  activatedTimestamp?: Date,
  expiredTimestamp?: Date | null | undefined
}

export const couponModelEmpty: ICouponModel = {
  id: 0,
  code: '',
  phoneNumber: '',
  status: 'Delivered',
  messageId: '',
  deliveredTimestamp: new Date(),
  activatedTimestamp: new Date(),
  expiredTimestamp: null,
};

export const reportModelEmpty: IReportModel = {
  id: 0,
  customerName: '',
  name: '',
  startDate: new Date(),
  endDate: new Date(),
  couponCount: 0,
  pricePerUser: 0,
  couponTotalPrice: 0,
  usedCouponCount: 0,
  unusedCouponCount: 0,
  state: CampaignState.NOT_STARTED,
  groupNames: [],
  coupons: [couponModelEmpty],
};
