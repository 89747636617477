import * as React from 'react';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useTranslation} from 'react-i18next';
import fi from 'date-fns/locale/fi';
import {ITemplateModel} from '../../models/template/ITemplateModel';
import {handleError} from '../common/Error';
import {useKeycloak} from '@react-keycloak/web';
import {createTemplate, updateTemplate} from '../../utils/RestClient';
import BasicInfoSection from '../campaign/BasicInfoSection';
import MessageSection from '../campaign/MessageSection';
import GroupSelectFilterComponent from '../usermanagement/GroupSelectFilterComponent';
import {IGroupModel} from '../../models/usermanagement/IGroupModel';
import {Views} from '../common/router/AppRouter';
import {AlertManager, useAlert} from 'react-alert';

const CreateTemplateView = ({template, edit}: { template: ITemplateModel, edit: boolean }) => {
  const history = useHistory<any>();
  if (history?.location.state?.template) {
    template = history.location.state.template;
  }
  const alert: AlertManager = useAlert();
  const [templateState, setTemplateState] = useState(template);
  const {t} = useTranslation();
  const {keycloak} = useKeycloak();
  // Put uploaded files into state until we post the data
  const [logo, setLogo] = useState('');
  const [banner, setBanner] = useState('');
  const [qrCode, setQrCode] = useState('');
  registerLocale('fi', fi);
  const isEdit: boolean = template.id > 0;

  function handleSubmit(event: any) {
    event.preventDefault();
    const formData = new FormData();
    if (logo) {
      formData.append('logo', logo);
    }
    if (banner) {
      formData.append('banner', banner);
    }
    if (qrCode) {
      formData.append('qrcode', qrCode);
    }
    formData.append('template', new Blob([JSON.stringify(templateState)], {type: 'application/json'}));

    if (templateState.id > 0) {
      updateTemplate(templateState, keycloak, formData).then(() => {
        history.push(Views.listTemplates);
      }).catch((e) => handleError(e, alert, t));
    } else {
      createTemplate(templateState, keycloak, formData).then(() => {
        history.push(Views.listTemplates);
      }).catch((e) => handleError(e, alert, t));
    }
  }


  function handleChange(event: any) {
    const {name, value} = event.currentTarget;
    if (event.target.files) {
      const file = event.target.files[0];
      if (name == 'bannerUrl') {
        setBanner(file);
      } else if (name == 'logoUrl') {
        setLogo(file);
      } else if (name == 'qrCodeUrl') {
        setQrCode(file);
      }
    } else {
      setTemplateState({...templateState, [name]: value});
    }
  }

  function removeAttachment(name: string) {
    if (name == 'bannerUrl') {
      setBanner('');
    } else if (name == 'logoUrl') {
      setLogo('');
    } else if (name == 'qrCodeUrl') {
      setQrCode('');
    }
    setTemplateState({...templateState, [name]: ''});
  }

  function handleBackClick() {
    history.goBack();
  }

  return (
    <div className="fullscreen-form">
      <form onSubmit={handleSubmit} className="templateForm">
        <div className="row" hidden={isEdit}>
          <div className="col"><h2>{t('Templates.create')}</h2></div>
        </div>
        <div className="row" hidden={!isEdit}>
          <div className="col"><h2>{t('Templates.edit')}</h2></div>
        </div>

        <div className="row">
          <div className="col">
            <label htmlFor="name" className="form-label">{t('Templates.templatename')}</label>
            <input id="name" type="text" className="form-control" name="campaignName"
              defaultValue={templateState.campaignName} onChange={handleChange} disabled={!edit}
              required/>
          </div>
          <div className="col">
            <label htmlFor="name" className="form-label">{t('Templates.addgroup')}</label>
            <GroupSelectFilterComponent addGroup={(group: IGroupModel) => {
              const groups = templateState.groups || [];
              if (!groups.some((g) => g.id == group.id)) {
                groups.push(group);
                setTemplateState({...templateState, groups: groups});
              }
            }} removeGroup={(group: IGroupModel) => {
              const groups = templateState.groups || [];
              const index = groups.indexOf(group);
              if (index > -1) {
                groups.splice(index, 1);
                setTemplateState({...templateState, groups: groups});
              }
            }} groups={templateState.groups}/>
          </div>
        </div>
        <div className="row">
          <div className="col"><h3>{t('CreateCampaign.nameAndValue')}</h3></div>
        </div>
        <BasicInfoSection campaign={templateState} edit={edit} handleChange={handleChange}/>
        <div className="row">
          <div className="col"><h3>{t('CreateCampaign.messagePage')}</h3></div>
        </div>
        <div className="row m-0">
          <div className="col m-0 p-0">
            <div className="row">
              <div className="col">
                <label htmlFor="Title" className="form-label">{t('CreateCampaign.messageHeader')}</label>
                <textarea className="form-control" aria-label="Title"
                  defaultValue={templateState.title} disabled={!edit} name="title"
                  onChange={handleChange}
                  required/>
              </div>
            </div>
            <MessageSection model={templateState} edit={edit} handleChange={handleChange}
              removeFile={removeAttachment}/>
          </div>
          <div className="col-auto my-4">
            <img src="/img/kuponki_layout.png" alt="kuponki" className="layout-img"/>
          </div>
        </div>
        <div className="row py-5">
          <div className="col text-center">
            <button className="btn btn-primary" onClick={handleBackClick}>{t('Exit')}</button>
          </div>

          <div className="col text-center">
            <button type="submit" className="btn btn-primary">{t('Save')}</button>
          </div>
        </div>
        ;
      </form>
    </div>
  );
};
export default CreateTemplateView;
