import * as React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {useKeycloak} from '@react-keycloak/web';
import Login from '../../Login';
import Header from '../Header';
import Loading from '../Loading';
import Error from '../Error';
import {PrivateRoute} from './PrivateRoute';
import MainView from '../../campaign/MainView';
import ViewCampaignView from '../../campaign/ViewCampaignView';
import CreateCampaignView from '../../campaign/CreateCampaignView';
import {campaignModelEmpty} from '../../../models/campaign/ICampaignModel';
import CampaignsView from '../../campaign/CampaignsView';
import UserManagementView from '../../usermanagement/UserManagementView';
import GroupManagementView from '../../usermanagement/GroupManagementView';
import TemplatesView from '../../template/TemplatesView';
import Footer from '../Footer';
import {Role} from '../../../utils/AuthUtil';
import ReportsView from '../../report/ReportsView';
import CouponView from '../../../mobile/CouponView';
import CreateTemplateView from '../../template/CreateTemplateView';
import {templateModelEmpty} from '../../../models/template/ITemplateModel';
import ViewReport from '../../report/ViewReport';
import {getCampaignDefaultEndDate, getCampaignDefaultStartDate} from '../../../utils/DateUtils';

export enum Views {
  login = '/login',
  error = '/error',
  viewCampaign = '/view',
  createCampaign = '/create',
  listCampaigns = '/list',
  listUsers = '/users',
  listGroups = '/groups',
  listTemplates = '/templates',
  createTemplate = '/createtemplate',
  reload = '/reload',
  reporting = '/reporting',
  reportView = '/reportview',
  couponView = '/c/'
}

export const AppRouter = ({keyCloakError}: any) => {
  const allRoles: Role[] = [Role.ROLE_ADMIN, Role.ROLE_OPERATOR, Role.ROLE_USER, Role.ROLE_GUEST];
  const allRolesWithoutGuest: Role[] = [Role.ROLE_ADMIN, Role.ROLE_OPERATOR, Role.ROLE_USER];
  const {initialized, keycloak} = useKeycloak();
  if (keyCloakError) {
    return <Error message={'Ei yhteyttä autentikointipalveluun!'}/>;
  }
  if (!initialized) {
    return <Loading/>;
  }
  const userInfo: any = keycloak.userInfo;

  const detail = {
    user: userInfo ? userInfo.email : '',
    name: 'mplaitinen.fi',
    email: 'info@mplaitinen.fi',
    phone: '014 613 905',
    address: 'Yritystie 15,',
    address2: '40320 Jyväskylä',
  };

  return (
    <BrowserRouter>
      <Header/>
      <Switch>
        <Route path={Views.login}>
          <Login/>
        </Route>
        <Route path={Views.error}>
          <Error/>
        </Route>
        <Route exact={true} path={Views.couponView + ':id'}>
          <CouponView/>
        </Route>
        {/* TODO: Second route for coupon, until we know what is the final route */}
        <Route exact={true} path={'/coupons/code/' + ':id'}>
          <CouponView/>
        </Route>
        <PrivateRoute path="/" exact={true} allowedRoles={allRolesWithoutGuest} keycloak={keycloak}>
          <MainView/>
        </PrivateRoute>
        <PrivateRoute path={Views.viewCampaign} allowedRoles={allRolesWithoutGuest} keycloak={keycloak}>
          <ViewCampaignView/>
        </PrivateRoute>
        <PrivateRoute path={Views.createCampaign} allowedRoles={allRolesWithoutGuest} keycloak={keycloak}>
          <CreateCampaignView campaign={{
            ...campaignModelEmpty,
            startDate: getCampaignDefaultStartDate(),
            endDate: getCampaignDefaultEndDate(),
          }}
          edit={true}/>
        </PrivateRoute>
        <PrivateRoute path={Views.listCampaigns} allowedRoles={allRolesWithoutGuest}
          keycloak={keycloak}><CampaignsView/></PrivateRoute>
        <PrivateRoute path={Views.listUsers}
          allowedRoles={[Role.ROLE_ADMIN, Role.ROLE_OPERATOR, Role.ROLE_USER]}
          keycloak={keycloak}><UserManagementView/></PrivateRoute>
        <PrivateRoute path={Views.listGroups} allowedRoles={[Role.ROLE_ADMIN, Role.ROLE_OPERATOR]}
          keycloak={keycloak}><GroupManagementView/></PrivateRoute>
        <PrivateRoute path={Views.listTemplates}
          allowedRoles={[Role.ROLE_ADMIN, Role.ROLE_OPERATOR, Role.ROLE_USER]}
          keycloak={keycloak}><TemplatesView/></PrivateRoute>
        <PrivateRoute path={Views.createTemplate}
          allowedRoles={[Role.ROLE_ADMIN, Role.ROLE_OPERATOR]}
          keycloak={keycloak}><CreateTemplateView template={templateModelEmpty} edit={true}/></PrivateRoute>
        <PrivateRoute path={Views.reload} allowedRoles={allRoles} keycloak={keycloak}><></>
        </PrivateRoute>
        <PrivateRoute path={Views.reporting} allowedRoles={allRoles}
          keycloak={keycloak}><ReportsView/></PrivateRoute>
        <PrivateRoute path={Views.reportView} allowedRoles={allRoles} keycloak={keycloak}>
          <ViewReport/>
        </PrivateRoute>
      </Switch>
      <Footer params={detail}/>
    </BrowserRouter>
  );
};
