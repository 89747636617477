import * as React from 'react';
import {useHistory} from 'react-router-dom';
import CreateCampaignView from './CreateCampaignView';
import CampaignSendMessages from './CampaignSendMessages';
import {useTranslation} from 'react-i18next';
import {campaignModelEmpty} from '../../models/campaign/ICampaignModel';

const ViewCampaignView = () => {
  const {t} = useTranslation();
  const history = useHistory<any>();
  let campaign = campaignModelEmpty;
  if (history?.location.state?.campaign) {
    campaign = history.location.state.campaign;
  }

  return (
    <>
      <div className="container-sm">
        <div className="row mb-5">
          <h1 id="top-header" className="text-center">{t('Campaigns.campaign')}: {campaign.name}</h1>
        </div>
        <div className="row">
          <div className="col fw-bold">{t('Campaigns.id')}</div>
          <div className="col fw-bold">{t('Campaigns.codes')}</div>
          <div className="col fw-bold">{t('Campaigns.value')}</div>
          <div className="col fw-bold">{t('Campaigns.totalvalue')}</div>
        </div>
        <div className="row">
          <div className="col">{campaign.id}</div>
          <div className="col">{campaign.couponCount}</div>
          <div className="col">{campaign.valuePerUser}</div>
          <div className="col">{campaign.couponTotalPrice}</div>
        </div>
      </div>
      <CampaignSendMessages campaign={campaign}/>
      <CreateCampaignView campaign={campaign} edit={false} viewMode={true}/>
    </>);
};

export default ViewCampaignView;
