import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Paging from '../common/Paging';
import ReportTable from './ReportTable';
import GroupSelectFilterComponent from '../usermanagement/GroupSelectFilterComponent';
import {CampaignState} from '../../models/campaign/ICampaignModel';
import {emptyPageable} from '../../models/common/IPageable';
import {fetchReports, fetchReportsCSV} from '../../utils/RestClient';
import {useHistory} from 'react-router-dom';
import Loading from '../common/Loading';
import Error from '../common/Error';
import CampaignStateButton from '../common/CampaignStateButton';
import {IGroupModel} from '../../models/usermanagement/IGroupModel';
import {useKeycloak} from '@react-keycloak/web';
import DateUtils from '../../utils/DateUtils';

const ReportsView = () => {
  const {keycloak} = useKeycloak();
  const {t} = useTranslation();
  const history = useHistory<any>();
  let filterParam = CampaignState.ANY;
  if (history?.location.state?.filter) {
    filterParam = history.location.state.filter;
  }
  const [page, setPage] = useState(emptyPageable);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<CampaignState>(filterParam);
  const [groupFilter, setGroupFilter] = useState<IGroupModel[]>([]);
  const {data, error, isLoading} = fetchReports(page, search, filter, groupFilter);

  if (isLoading && !data.content) {
    return (<Loading/>);
  }
  if (error) {
    return (<Error message={t('error.fetch') + ' (' + error.message + ') '}/>);
  }

  function onStateFilter(state: CampaignState) {
    setFilter(state);
    changePage(0);
  }

  function onGroupFilter(group: IGroupModel, add: boolean = true) {
    const groups = groupFilter;
    const index = groups.indexOf(group);
    if (add) {
      if (index < 0) {
        groups.push(group);
      }
    } else {
      // Remove
      if (index > -1) {
        groups.splice(index, 1);
      }
    }
    setGroupFilter(groups);
    changePage(0);
  }

  function changePage(pageNbr: number) {
    setPage({...page, pageNumber: pageNbr});
  }

  function onSearch(event: any) {
    const searchTerm = event.target.value;
    if (searchTerm && searchTerm.length > 2) {
      setSearch(searchTerm);
      changePage(0);
    } else if (searchTerm.length == 0) {
      setSearch('');
      changePage(0);
    }
  }

  function downloadCSV() {
    fetchReportsCSV(keycloak, page, search, filter, groupFilter).then((res: any) => {
      // For download we need to do this
      const element = document.createElement('a');
      const file = new Blob(['\uFEFF' + res.data], {type: 'text/csv'});
      element.href = URL.createObjectURL(file);
      element.download = 'report_' + DateUtils.getShortStringFromDate(new Date()) + '.csv';
      document.body.appendChild(element);
      element.click();
    });
  }

  return (<div className="container-fluid">
    <div className="container-sm p-2">
      <h1 id="top-header" className="text-center py-2">{t('Reporting.header')}</h1>
      <div className="row w-100 py-3 align-items-center">
        <div className="col-2">
          <span className="row fw-bold">{t('Reporting.campaignsTotal')}</span> {data?.totalCampaigns} kpl
        </div>
        <div className="col-2">
          <span className="row fw-bold">{t('Reporting.codesTotal')}</span>{data?.totalCoupons}
        </div>
        <div className="col-2">
          <span className="row fw-bold">{t('Reporting.valueTotal')}</span>{Math.round(data?.totalPrice)} €
        </div>
        <div className="col-5">
          <div className="row">
            <label htmlFor="userSearch"
              className="col text-end col-form-label">{t('CampaignTable.col.client')}</label>
            <div className="col">
              <GroupSelectFilterComponent addGroup={onGroupFilter} removeGroup={(g: IGroupModel) => {
                onGroupFilter(g, false);
              }} groups={groupFilter}/>
            </div>
          </div>
          <div className="row">
            <label htmlFor="reportSearch" className="col text-end col-form-label">{t('SearchPhrase')}</label>
            <div className="col">
              <input type="text" id="reportSearch" name="reportSearch" className="form-control"
                onChange={onSearch} placeholder={t('Campaigns.filter.search')}/>
            </div>
          </div>
        </div>
      </div>
      <div id="filter-buttons" className="row w-100">
        <div className="col-auto py-5">
          <CampaignStateButton filter={CampaignState.ANY} filterState={filter} onFilter={onStateFilter}
            label={t('Campaigns.filter.all')}/>
        </div>
        <div className="col-auto py-5">
          <CampaignStateButton filter={CampaignState.ACTIVE} filterState={filter} onFilter={onStateFilter}
            label={t('Campaigns.filter.active')}/>
        </div>
        <div className="col-auto py-5">
          <CampaignStateButton filter={CampaignState.STARTING} filterState={filter} onFilter={onStateFilter}
            label={t('Campaigns.filter.starting')}/>
        </div>
        <div className="col-auto py-5">
          <CampaignStateButton filter={CampaignState.FINISHED} filterState={filter} onFilter={onStateFilter}
            label={t('Campaigns.filter.ended')}/>
        </div>
        <div className="col-auto py-5">
          <CampaignStateButton filter={CampaignState.ATTACHED} filterState={filter} onFilter={onStateFilter}
            label={t('Campaigns.filter.attached')}/>
        </div>
        <div className="col-auto py-5">
          <CampaignStateButton filter={CampaignState.ARCHIVED} filterState={filter} onFilter={onStateFilter}
            label={t('Campaigns.filter.archived')}/>
        </div>
        <div className="col py-5 text-end">
          <button className="btn btn-danger" style={{opacity: '70%'}} onClick={downloadCSV}>Lataa CSV</button>
        </div>
      </div>
      <ReportTable models={data.campaigns || []}/>
    </div>
    <Paging changePage={changePage} itemCount={data.totalCampaigns || 0} currentPage={page.pageNumber}
      pageSize={page.pageSize}/>
  </div>
  );
};

export default ReportsView;
