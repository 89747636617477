import {IGroupModel} from '../../models/usermanagement/IGroupModel';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import SelectSearch, {fuzzySearch, SelectSearchOption} from 'react-select-search';
import {fetchGroupsPromise} from '../../utils/RestClient';
import {useKeycloak} from '@react-keycloak/web';
import {handleError} from '../common/Error';

const GroupSelectFilterComponent = ({
  addGroup,
  removeGroup,
  groups,
  enabled = true,
  groupsFetched,
}: { addGroup: any, removeGroup: any, groups: IGroupModel[], enabled?: boolean, groupsFetched?: any }) => {
  const {t} = useTranslation();
  const {keycloak} = useKeycloak();
  const [groupsState, setGroupsState] = useState<IGroupModel[]>();

  async function fetchGroupList(query?: string): Promise<SelectSearchOption[]> {
    if (groupsState && !query) {
      return mapGroupsToSelectSearchOption(groupsState);
    }
    const res = await fetchGroupsPromise(keycloak, query).catch(handleError);
    if (!query && res) {
      setGroupsState(res.data?.content);
      if (groupsFetched) {
        groupsFetched(res.data?.content);
      }
    }
    return mapGroupsToSelectSearchOption(res?.data?.content);
  }

  function mapGroupsToSelectSearchOption(groupArr: any): SelectSearchOption[] {
    return groupArr?.map((group: any) => {
      return {value: group.id, name: group.name};
    }) ?? [];
  }

  function resolveGroup(groupId: any) {
    const group: any = groupsState?.find((g: any) => g.id == groupId);
    addGroup(group);
  }

  return (<>
    <SelectSearch className="select-search" options={[]} search={true}
      filterOptions={fuzzySearch} id="groupSelect" disabled={!enabled}
      placeholder={t('CreateUser.findGroup')} onChange={resolveGroup}
      getOptions={fetchGroupList}/>
    <table className="table-sm group-table">
      <tbody>
        {groups?.map((g: IGroupModel, index: number) => (
          <tr key={index} className="small">
            <td>
              {g.name}</td>
            <td>
              <button type="button" disabled={!enabled}
                onClick={() => removeGroup(g)}
                className="btn btn-link">{t('Delete')}</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </>);
};
export default GroupSelectFilterComponent;

